import React, {useState,useEffect,useRef,useContext,useReducer} from 'react'

import { CONSTANTS, TIME_FRAME_VALUES,BOOKMARK_CATEGORY_AUTOSAVE } from '../../common/utility/constant'
import MPChartHeaderSettings from './components/mpchart_header'
import { Divider } from '@mui/material';
import MPChartRightPanel from './components/mpchart_right_panel';
import MPChartFooter from './components/mpchart_footer';
import MPChart from './components/mpchart'
import { useAxiosWithAuth } from '../../common/api/hooks/useAxiosWithAuth';
import { URL } from '../../common/api/urls';
import useAuth from '../../common/hooks/useAuth';
import { USER_ROLE_FIELDS,USER_RESOURCES_LIST,MP_CHART_RELOAD_TIME } from '../../common/utility/constant';
import { CircularProgress } from '@mui/material';
import { TEXT_MSGS,SNACKBAR_AUTO_HIDE_DURATION } from '../../common/utility/constant';
import {Snackbar, Alert} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { formatDatesToISO, useQuery } from '../../common/utility/helperFunc';
import { UserSettingsContext } from '../../setup/routes-manager/RequireAuth'
import { VA_TYPE,VP_TYPE,MAX_PROFILE_TO_LOAD } from '../../common/utility/mp_chart_constants';
import { CHART_TYPE } from '../../common/utility/mp_chart_constants';
import LineChart from './line_chart';
import StockChartHV from './stock_chart_hv';
import BarChart from './bar_chart';
import ConfirmActionDialog from './components/confirm_dialog';
import {useSearchParams} from 'react-router-dom';
import PRICE_TABLE from './components/price_table';
import OrderflowContainer from './orderflow_container';
import { breakDSTR } from '../../common/utility/helperFunc';
import LineChartContainer from './LineChartContainer';
import BarChartContainer from './BarChartContainer';
import MPSettings from './components/mp_settings';
import LineChartContainerNew from './LineChartContainerNew';
import dayjs from "dayjs";




// const DEFAULT_PROFILE_WIDTH=120;
// const DEFAULT_PROFILE_WIDTH_WEEKLY=200;
// const DEFAULT_PROFILE_WIDTH_MONTHLY=200;
// const DEFAULT_PROFILE_WIDTH_YEARLY=400;
const DEFAULT_PROFILE_WIDTH=60;
const DEFAULT_PROFILE_WIDTH_WEEKLY=100;
const DEFAULT_PROFILE_WIDTH_MONTHLY=120;
const DEFAULT_PROFILE_WIDTH_YEARLY=400;
const PROFILE_LOAD_FACTOR=1.2;

const ChartContainer = (props) => {
  
  // console.log("props==========================>",props);
  const [tabData,setTabData]=useState(props?.tabData??undefined)
  const [initialData,setInitialData]=useState((props?.tabData?.data?.[props.val] &&props?.tabData?.data?.[props.val].type) ?props?.tabData?.data?.[props.val]: undefined)
  // const [initialData,setInitialData]=useState(props?.tabData ?? undefined)
  const { auth } = useAuth();
  const isEliteUser=(auth.resources.find(el=>el==USER_RESOURCES_LIST.TBT_FEED) && auth.role.find(el=>el==USER_ROLE_FIELDS.PRO))
  const location = useLocation();
  // const [chartType,setChartType]=useState(props.chartType?props.chartType:CHART_TYPE.MARKET_PROFILE)
  const [chartType, setChartType] = useState(initialData?.type ?? props.chartType ?? CHART_TYPE.MARKET_PROFILE);
  // const [chartType, setChartType] = useState(props.chartType ?? CHART_TYPE.MARKET_PROFILE);
  // const { userSettings, setUserSettings } = useContext(UserSettingsContext);
  const { userSettings,setUserSettings, UIData } = useContext(UserSettingsContext);
  console.log("Testing User Settings:", userSettings);
    console.log("Testing UI Data:", UIData);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  // const from = location.state?.from?.pathname || "/login";
  const from = "/login";
  const symbol = useQuery("symbol");
  console.log("props.key 1=",props.key,props.val)
  const [state, setState] = useState({
    isLoading:true,
    liveData:undefined,
    chartData:undefined,
    krlData:undefined,
    instrument:initialData?.instrument??(props.symbol?props.symbol:(symbol&&props.val==0?symbol:userSettings.data.default_instrument)),
    // instrument:symbol&&props.val==0?symbol:userSettings.data.default_instrument,
    isIncrementalData:false,
    lastLoadTime:-1,
    isInstrumentLive:false,
    reloadInerval:(auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))? (auth.resources.find(el=>el==USER_RESOURCES_LIST.TBT_FEED)? MP_CHART_RELOAD_TIME.FAST_DATA:MP_CHART_RELOAD_TIME.MINWISE_DATA):(MP_CHART_RELOAD_TIME.SLOW_DATA),
    pollingInterval:MP_CHART_RELOAD_TIME.POLLING_TIME,
    refreshInterval:-1,
    selectedTimeFrame:"daily",
    isPollingAllowed:false,
    isTradingSessionActive:false,
    isJsonLoaded:false,
    isFirtLoadComplete:false,
    apiData:undefined,
    bookmarkData:undefined,
    isStatusCheckAllowed:false,
    statusCheckPollingInterval:MP_CHART_RELOAD_TIME.POLLING_TIME,
    isLivePollingAllowed:false,
    krlPollingTime:(auth.role.find(el=>el==USER_ROLE_FIELDS.ADMIN))?MP_CHART_RELOAD_TIME.FAST_KRL_POLLING_TIME:MP_CHART_RELOAD_TIME.KRL_POLLING_TIME,
    krlPollingAllowed:false,
    tradeDates:undefined,
    dstrData:undefined,
    chartRendered:false,
    selectedDateTime:null,
    selectedDateTimeLiveFlag:false,

  });

  const [footerLoaded,setFooterLoaded]=useState(false);
  const [npocAllowed,setNPOCAllowed]=useState((auth.role.find(el=>el==USER_ROLE_FIELDS.FAST_DATA))?true:false)
  
const [msgState, setMsgState] = useState({
  open: false,
  msg:"" ,
  severity:"info"
});

const { open, msg, severity } = msgState;

  const {isLoading,chartData,liveData,krlData, isIncrementalData,isInstrumentLive,lastLoadTime,reloadInerval,pollingInterval,instrument,refreshInterval,selectedTimeFrame,
    isPollingAllowed,isTradingSessionActive,isJsonLoaded,isFirtLoadComplete,apiData,bookmarkData,isStatusCheckAllowed,statusCheckPollingInterval,isLivePollingAllowed,krlPollingTime,
    krlPollingAllowed,tradeDates,dstrData,chartRendered,selectedDateTime,selectedDateTimeLiveFlag} = state;
  const stateRef = useRef(state);
  const statusCheckIntervalRef = useRef(null);
  const krlPollingIntervalRef = useRef(null);
  const refreshIntervalRef = useRef(null);
 
  
const [cancel,responseData,error,loaded,reset,executeAPI] = useAxiosWithAuth();
const [cancelLive,responseDataLive,errorLive,loadedLive,resetLive,executeLive] = useAxiosWithAuth();
const [cancelSaved,responseDataSaved,errorSaved,loadedSaved,resetSaved,executeAPISaved] = useAxiosWithAuth();
const [cancelDelete,responseDataDelete, errorDelete, loadedDelete, resetDelete,executeAPIDelete ] = useAxiosWithAuth();
const [cancelStatus,responseDataStatus,errorStatus,loadedStatus,resetStatus,executeAPIStatus] = useAxiosWithAuth();
const [cancelKRL,responseDataKRL,errorKRL,loadedKRL,resetKRL,executeAPIKRL] = useAxiosWithAuth();
const [cancelKRLPoll,responseDataKRLPoll,errorKRLPoll,loadedKRLPoll,resetKRLPoll,executeAPIKRLPoll] = useAxiosWithAuth();


  const [selectedInstrument,setSelectedInstrument]=useState()
  const selectedInstrumentRef=useRef(selectedInstrument)
  const [selectedTPO,setSelectedTPO]=useState(2)
  const [selectedInstrumentData,setSelectedInstrumentData]=useState();
  const [selectedInstrumentTPOData,setSelectedInstrumentTPOData]=useState();
  const [globalVolumeVisible,setGlobalVolumeVisible]=useState(userSettings.data.show_global_vp);
  const [volumeNumberVisible,setVolumeNumberVisible]=useState(userSettings.data.show_global_vp_numbers);
  const [onlyVolumeProfile,setOnlyVolumeProfile]=useState(false);
  const [tpoOnVolume,setTpoOnVolume]=useState(false);
  const [perProfilelVolumeVisible,setPerProfileVolumeVisible]=useState(false);
  const [perProfileVolumeNumberVisible,setProfileVolumeNumberVisible]=useState(userSettings?.data?.show_profile_vp_numbers?true:false);
  const navigate = useNavigate(); 
  const [autoCenter, setAutocenter] = useState(false);
  // const [viewState, setViewState] = React.useState({tpo_only:true,vol_tpo_side:false,only_vol:false,tpo_on_vol:false});
  const [viewState, setViewState] = React.useState({tpo_only:(userSettings.data.vp_type==VP_TYPE.NONE||userSettings.data.vp_type==VP_TYPE.BACKGROUND||userSettings.data.vp_type==VP_TYPE.ASIDE),vol_tpo_side:(userSettings.data.vp_type==VP_TYPE.ASIDE),only_vol:(userSettings.data.vp_type==VP_TYPE.ALONE),tpo_on_vol:(userSettings.data.vp_type==VP_TYPE.BACKGROUND)})
 
  const [tpoBasedVA,setTPOBasedVA]=useState(userSettings.data.va_type);
  const [toggleReset,setToggleReset]=useState(false);
  const [toggleUnselect,setToggleUnselect]=useState(false);
  const [rightPanelConatinerState,setRightPanelConatinerState]=useState({
    show:false,
    metadata:undefined,
    histoticalData:undefined,
    selectedIndexes:[],
    isLiveSession:false
  })
  const [toggleSave,setToggleSave]=useState(false);
  const [toggleSaveBookmark,setToggleBookmark,]=useState(false);
  const [jsonData,setJsonData]=useState();
  const [vpType,setVPType]= React.useState(userSettings.data.vp_type);
  const [stateData,setStateData]=useState();
  const [openAutosaveDeleteDialog,setOpenAutosaveDeleteDialog]=useState(false);
  const [reloadInstrument,setReloadInstrument]=useState();
  const [isDirty,setIsDirty]=useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [toggleMPLoading,setToggleMPLoading]=useState(false);
  const [showTable,setShowTable]=useState(false);
  const [tableData,setTableData]=useState(false);
  const [levelData,setLevelData]=useState([-1,-1]);
  const [showSettings,setShowSettings]=useState(false);
  const mpTradeDates=formatDatesToISO(UIData.tradeDates);

  
  const openStockChart=()=>{
    navigate(`/stock-chart?type=SINGLE&symbol=${selectedInstrument}&startDate=&nDays=0`, { replace: false });   
  }

  useEffect(()=>{
    // executeAPIKRL(URL.GET_KRL_LIST,"POST",{"symbol":"NIFTY"})
    console.log("ISSUEOF MP Settings props MP container=",props,userSettings,chartType,initialData);
    if(props.setChartType)
    props.setChartType(chartType);
    const timeout = setTimeout(() => {
      setTabData(undefined);
      setInitialData(undefined);
      console.log("Reload props LC container setInitialData=undefined"); 
  }, 1000);
  return () => {
    // just to clear the timeout when component unmounts
    clearTimeout(timeout)
  }
  },[])

  const openMPChart=()=>{
    setShowTable(false);
    if(chartType!=CHART_TYPE.MARKET_PROFILE){
      console.log("live panel resetRightPanel===1")
      props.resetRightPanel(); 
      // console.log("state=",state);
      setPollingAllowedVal(true)
      setChartType(CHART_TYPE.MARKET_PROFILE)
      if(props.setChartType!=undefined)
      props.setChartType(CHART_TYPE.MARKET_PROFILE)
      
      // setState((prevState) => ({
      //   ...prevState,
      //   isLoading:false}));
      
      // setSelectedInstrument(selectedInstrument);
      }
    // navigate(`/stock-chart?type=SINGLE&symbol=${selectedInstrument}&startDate=&nDays=0`, { replace: false });   
  }
  
  const openLineChart=()=>{
    
    // if(chartType!=CHART_TYPE.LINE_CHART){
      console.log("selected instrument",props.val,selectedInstrument);
      console.log("live panel resetRightPanel===2")
      props.resetRightPanel(); 
      setPollingAllowedVal(false)
      setStateData(undefined);
      setChartType(CHART_TYPE.LINE_CHART)
      if(props.setChartType!=undefined)
      props.setChartType(CHART_TYPE.LINE_CHART)
    // }
    // navigate(`/line-chart?type=SINGLE&symbol=${selectedInstrument}&startDate=&nDays=0`, { replace: false });   
  }

  const openOFChart=()=>{
    setShowTable(false);
    if(chartType!=CHART_TYPE.OF_CHART){
      console.log("live panel resetRightPanel===1")
      props.resetRightPanel(); 
      // console.log("state=",state);
      setPollingAllowedVal(false)
      setChartType(CHART_TYPE.OF_CHART)
      if(props.setChartType!=undefined)
      props.setChartType(CHART_TYPE.OF_CHART)
      
     
      }
     
  }

  useEffect(()=>{
    console.log("Chart container Props changes, proUser",props.chartStateData,props.proUser)
  },[props.chartStateData])

  // useEffect(() => {
  //   // if (selectedInstrument)
  //   //   document.title = "MP Charts - " + selectedInstrument;
  //   // var link = !document.querySelector("link[rel='canonical']") ? document.querySelector("link[rel='canonical']") : document.createElement('link');
  //   // link.setAttribute('rel', 'canonical');
  //   // link.setAttribute('href', document.location.protocol + '//' + document.location.host + document.location.pathname);
  //   // document.head.appendChild(link);
  //   // var meta = !document.querySelector("meta[name='description']") ? document.querySelector("meta[name='description']") : document.createElement('meta');
  //   // meta.setAttribute('name', 'description');
  //   // meta.setAttribute('content', 'Market Profile charts. Futures and Options - individual, straddle, strangle. Nifty. BankNifty. FinNifty.');
  //   // document.head.appendChild(meta);
  // }, []);

  const loadAutoSaveData=(val)=>{
    console.log("LoadAutsave dat val====",val);
    executeAPISaved(`${URL.GET_BOOKMARK_DATA}?id=${val}`,"GET",{});
  }

   /**
 * API response handler  auto saved data
 */  
   useEffect(() => {
    if(loadedSaved){
      console.log("Create bookmark krl data loaded Save=true",responseDataSaved,responseDataSaved?.data?.category);
      if(responseDataSaved!=null){
        loadInitialState(responseDataSaved);
        return;
        setState((prevState) => ({
          ...prevState,
          dstrData:undefined}));
           console.log("Saved data loaded",responseDataSaved.data)
           const jsonData=responseDataSaved.data.data;
           let UIData=JSON.parse(localStorage.getItem("UIData"));
           let instrIndex=(UIData!=undefined&& UIData!=null)?UIData.instruments.findIndex(item => item.instr == jsonData.instrument):0;
           if (instrIndex==-1) instrIndex=0;
           let finalData=[];
           let data=undefined;
           if(jsonData.tf!="daily"){
          //  setJsonData(jsonData);
           
          data={
            "symbol": jsonData.instrument,
            "nDays":0,
            "nProf": "",
            // "nProf": 2,
            "dstr": jsonData.dstr,   //
            "liveData": true,
            "liveOnly": false,
            "liveDataComposite": false,
            "startDate":jsonData.startDate,
            // "endDate": "",
            "tf": jsonData.tf,
            "max_cmp_days":parseInt(userSettings.data.max_ndays_for_composite),
            "contracts": userSettings.data.data_in_contracts,
            "show_oi_spikes": userSettings.data.show_split_profile_oi_spikes!=undefined?userSettings.data.show_split_profile_oi_spikes:true,
            "show_tpova_markers": userSettings.data.show_split_profile_va_markers!=undefined?userSettings.data.show_split_profile_va_markers:true,
            "upperLevel":(jsonData.upperLevel? jsonData.upperLevel : undefined),
            "lowerLevel":(jsonData.lowerLevel? jsonData.lowerLevel : undefined),
            "bookmark_id":responseDataSaved.data.category!="AUTO_SAVED"?responseDataSaved.data.id:undefined,
            "krl":userSettings.data.show_krl||userSettings.data.custom_krl_display?1:undefined
          }
           }
           else{
           finalData=breakDSTR(jsonData.dstr,MAX_PROFILE_TO_LOAD,UIData.tradeDates,jsonData.instrument,jsonData.startDate,UIData.instruments[instrIndex],jsonData)
          //  console.log("breakDSTR returned=",finalData,instrIndex)
           document.title = "MP Charts - " + jsonData.instrument.replace('O_','');
           finalData[0].loaded=true;
           console.log("breakDSTR returned=",finalData,instrIndex)
          

          
           data={
            "symbol": jsonData.instrument,
            "nDays":0,
            "nProf": "",
            // "nProf": 2,
            "dstr": finalData[0].dstr,   //
            "liveData": true,
            "liveOnly": false,
            "liveDataComposite": false,
            "startDate":finalData[0].startDate,
            // "endDate": "",
            "tf": jsonData.tf,
            "max_cmp_days":parseInt(userSettings.data.max_ndays_for_composite),
            "contracts": userSettings.data.data_in_contracts,
            "upperLevel":(jsonData.upperLevel? jsonData.upperLevel : undefined),
            "lowerLevel":(jsonData.lowerLevel? jsonData.lowerLevel : undefined),
            "show_oi_spikes": userSettings.data.show_split_profile_oi_spikes!=undefined?userSettings.data.show_split_profile_oi_spikes:true,
            "show_tpova_markers": userSettings.data.show_split_profile_va_markers!=undefined?userSettings.data.show_split_profile_va_markers:true,
            "getLatest":true,
            "bookmark_id":responseDataSaved.data.category!="AUTO_SAVED"?responseDataSaved.data.id:undefined,
            "krl":userSettings.data.show_krl||userSettings.data.custom_krl_display?1:undefined
          }
        }
         

          //save the symbol in the url
           if(responseDataSaved.data.category==BOOKMARK_CATEGORY_AUTOSAVE && props.val==0)
          setSearchParams({"symbol":jsonData.instrument})
          
          setSelectedTPO(jsonData.tpo);
          let bookmarkData=responseDataSaved.data;
         
          bookmarkData.last_saved=responseDataSaved.last_saved;
          setState((prevState) => ({
            ...prevState,
            isLoading:true,
            isJsonLoaded:true,
            selectedTimeFrame:data.tf,
            apiData:data,
            bookmarkData:responseDataSaved.data,
            selectedInstrument:jsonData.instrument,
            dstrData:finalData,
            instrument:jsonData.instrument}));
            setJsonData(jsonData);
            setFooterLoaded(true);
            
            if(jsonData.upperLevel && jsonData.lowerLevel){
              let levels=[];
              levels.push(jsonData.upperLevel);
              levels.push(jsonData.lowerLevel);
              setLevelData(levels.sort());
            }
            if(selectedInstrument!=undefined){
              executeAPI(URL.MPCHART,"POST",data);
              console.log("breakDSTR returned= execute api",finalData,instrIndex)
            }
            
      }
      else if(errorSaved!==null){
        // loadData();
        console.log("Error data=",errorSaved);
        setState((prevState) => ({
          ...prevState,
          isJsonLoaded:true,
          isFirtLoadComplete:true,
          isLoading:false}));
          
          // setMsgState({open:true,msg:errorSaved?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          //if unauthorized then redirec it to login page
          if(errorSaved?.response?.status === 401 || errorSaved?.response?.status === 403){
            console.log("status received =",errorSaved?.response?.status)
            navigate(from, { replace: true });
          }else 
          setMsgState({open:true,msg:errorSaved?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
      }
      resetSaved();
    }
    },[loadedSaved,responseDataSaved]);

 const loadData=()=>{
  
  
  let defaultWidth=DEFAULT_PROFILE_WIDTH;
  if(selectedTimeFrame==TIME_FRAME_VALUES.weekly|| selectedTimeFrame==TIME_FRAME_VALUES.weekly_series){
    defaultWidth=DEFAULT_PROFILE_WIDTH_WEEKLY;
  }else if(selectedTimeFrame==TIME_FRAME_VALUES.monthly||selectedTimeFrame==TIME_FRAME_VALUES.monthly_series){
    defaultWidth=DEFAULT_PROFILE_WIDTH_MONTHLY;
  }else if(selectedTimeFrame==TIME_FRAME_VALUES.yearly){
    defaultWidth=DEFAULT_PROFILE_WIDTH_YEARLY;
  }
  // console.log("usersettings=",userSettings.data)
  document.title = "MP Charts - " + selectedInstrument.replace('O_','');
  let nprof =  Math.round(PROFILE_LOAD_FACTOR*(window.innerWidth/defaultWidth));
  console.log("----nprof", nprof, PROFILE_LOAD_FACTOR, window.innerWidth, defaultWidth)
  if(selectedInstrument.includes("_E") && selectedTimeFrame==TIME_FRAME_VALUES.monthly_series)
    nprof = 3;
  console.log("----nprof", nprof, selectedInstrument, selectedTimeFrame);

  let data={
    "symbol": selectedInstrument,
    "nDays":0,
    "nProf": Math.min(nprof,MAX_PROFILE_TO_LOAD),
    // "nProf": 10,
    "dstr": "",   //
    "upperLevel":(levelData[0]!=-1 && levelData[1]!=-1 ? levelData[1] : undefined),
    "lowerLevel":(levelData[0]!=-1 && levelData[1]!=-1 ? levelData[0] : undefined),
    "liveData": true,
    "liveOnly": false,
    "liveDataComposite": false,
    "startDate":"",
    // "endDate": "",
    "tf": selectedTimeFrame,
    "max_cmp_days":parseInt(userSettings.data.max_ndays_for_composite),
    "contracts": userSettings.data.data_in_contracts,
    "show_oi_spikes": userSettings.data.show_split_profile_oi_spikes!=undefined?userSettings.data.show_split_profile_oi_spikes:true,
    "show_tpova_markers": userSettings.data.show_split_profile_va_markers!=undefined?userSettings.data.show_split_profile_va_markers:true,
    "krl":userSettings.data.show_krl||userSettings.data.custom_krl_display?1:undefined
  }

  if(selectedDateTime){
    let startDate = dayjs(selectedDateTime).format("DD-MM-YYYY");
    let index = UIData.tradeDates.findIndex(item => item === startDate);
    let liveFlag=false;
    if(index>=0){
      if(selectedTimeFrame==TIME_FRAME_VALUES.daily){
        if(index+15>UIData.tradeDates.length-1) liveFlag=true;
      }
      else if(selectedTimeFrame==TIME_FRAME_VALUES.weekly|| selectedTimeFrame==TIME_FRAME_VALUES.weekly_series){
        if(index+35>UIData.tradeDates.length-1) liveFlag=true;
      }
      else  if(selectedTimeFrame==TIME_FRAME_VALUES.monthly||selectedTimeFrame==TIME_FRAME_VALUES.monthly_series){
        if(index+240>UIData.tradeDates.length-1) liveFlag=true;
      }else if(selectedTimeFrame==TIME_FRAME_VALUES.yearly){
        if(index+1000>UIData.tradeDates.length-1) liveFlag=true;
      }
    }
    setState((prevState) => ({
      ...prevState,
      selectedDateTimeLiveFlag:liveFlag,
      }));

    data={
      "symbol": selectedInstrument,
      "nDays":0,
      "nProf": Math.min(nprof,MAX_PROFILE_TO_LOAD),
      // "nProf": 10,
      "dstr": "",   //
      // "upperLevel":(levelData[0]!=-1 && levelData[1]!=-1 ? levelData[1] : undefined),
      // "lowerLevel":(levelData[0]!=-1 && levelData[1]!=-1 ? levelData[0] : undefined),
      "liveData": liveFlag,
      "liveOnly": false,
      "liveDataComposite": false,
      "startDate":startDate,
      // "endDate": "",
      "tf": selectedTimeFrame,
      "getLatest":false,
      "max_cmp_days":parseInt(userSettings.data.max_ndays_for_composite),
      "contracts": userSettings.data.data_in_contracts,
      "show_oi_spikes": userSettings.data.show_split_profile_oi_spikes!=undefined?userSettings.data.show_split_profile_oi_spikes:true,
      "show_tpova_markers": userSettings.data.show_split_profile_va_markers!=undefined?userSettings.data.show_split_profile_va_markers:true,
      "krl":userSettings.data.show_krl||userSettings.data.custom_krl_display?1:undefined
    }
  }
  // console.log("chart api data = ",data,selectedInstrument);
  console.log("MPDateTest data=",data)
  setState((prevState) => ({
    ...prevState,
    isLoading:true,
    dstrData:undefined,
    dstrData:undefined,
    instrument:selectedInstrument}));
  
  executeAPI(URL.MPCHART,"POST",data);
 }  
 
 const loadInitialState=(responseDataSaved)=>{
  if(responseDataSaved!=null && responseDataSaved?.data?.data){
    setState((prevState) => ({
      ...prevState,
      dstrData:undefined}));
       console.log("Relaod Saved data loaded",responseDataSaved.data)
       const jsonData=responseDataSaved.data.data;
      //  let UIData=JSON.parse(localStorage.getItem("UIData"));
       let instrIndex=(UIData!=undefined&& UIData!=null)?UIData.instruments.findIndex(item => item.instr == jsonData.instrument):0;
       if (instrIndex==-1) instrIndex=0;
       setSelectedInstrumentData(UIData.instruments[instrIndex])
       let finalData=[];
       let data=undefined;
       if(jsonData.tf!="daily"){
      //  setJsonData(jsonData);
       
      data={
        "symbol": jsonData.instrument,
        "nDays":0,
        "nProf": "",
        // "nProf": 2,
        "dstr": jsonData.dstr,   //
        "liveData": true,
        "liveOnly": false,
        "liveDataComposite": false,
        "startDate":jsonData.startDate,
        // "endDate": "",
        "tf": jsonData.tf,
        "max_cmp_days":parseInt(userSettings.data.max_ndays_for_composite),
        "contracts": userSettings.data.data_in_contracts,
        "show_oi_spikes": userSettings.data.show_split_profile_oi_spikes!=undefined?userSettings.data.show_split_profile_oi_spikes:true,
        "show_tpova_markers": userSettings.data.show_split_profile_va_markers!=undefined?userSettings.data.show_split_profile_va_markers:true,
        "upperLevel":(jsonData.upperLevel? jsonData.upperLevel : undefined),
        "lowerLevel":(jsonData.lowerLevel? jsonData.lowerLevel : undefined),
        "getLatest":true,
        "bookmark_id":responseDataSaved.data.category!="AUTO_SAVED"?responseDataSaved.data.id:undefined,
        "krl":userSettings.data.show_krl||userSettings.data.custom_krl_display?1:undefined
      }
       }
       else{
       finalData=breakDSTR(jsonData.dstr,MAX_PROFILE_TO_LOAD,UIData.tradeDates,jsonData.instrument,jsonData.startDate,UIData.instruments[instrIndex],jsonData)
      console.log("Fufutest finalData=",finalData)
       //  console.log("breakDSTR returned=",finalData,instrIndex)
       document.title = "MP Charts - " + jsonData.instrument.replace('O_','');
       finalData[0].loaded=true;
      //  console.log("breakDSTR returned=",finalData,instrIndex)
      

      
       data={
        "symbol": jsonData.instrument,
        "nDays":0,
        "nProf": "",
        // "nProf": 2,
        "dstr": finalData[0].dstr,   //
        "liveData": true,
        "liveOnly": false,
        "liveDataComposite": false,
        "startDate":finalData[0].startDate,
        // "endDate": "",
        "tf": jsonData.tf,
        "max_cmp_days":parseInt(userSettings.data.max_ndays_for_composite),
        "contracts": userSettings.data.data_in_contracts,
        "upperLevel":(jsonData.upperLevel? jsonData.upperLevel : undefined),
        "lowerLevel":(jsonData.lowerLevel? jsonData.lowerLevel : undefined),
        "show_oi_spikes": userSettings.data.show_split_profile_oi_spikes!=undefined?userSettings.data.show_split_profile_oi_spikes:true,
        "show_tpova_markers": userSettings.data.show_split_profile_va_markers!=undefined?userSettings.data.show_split_profile_va_markers:true,
        "getLatest":true,
        "bookmark_id":responseDataSaved.data.category!="AUTO_SAVED"?responseDataSaved.data.id:undefined,
        "krl":userSettings.data.show_krl||userSettings.data.custom_krl_display?1:undefined
      }
    }
     

      //save the symbol in the url
       if(responseDataSaved.data.category==BOOKMARK_CATEGORY_AUTOSAVE && props.val==0)
      setSearchParams({"symbol":jsonData.instrument})
      
      setSelectedTPO(jsonData.tpo);
      let bookmarkData=responseDataSaved.data;
     
      bookmarkData.last_saved=responseDataSaved.last_saved;
      setState((prevState) => ({
        ...prevState,
        isLoading:true,
        isJsonLoaded:true,
        selectedTimeFrame:data.tf,
        apiData:data,
        bookmarkData:responseDataSaved.data,
        selectedInstrument:jsonData.instrument,
        dstrData:finalData,
        instrument:jsonData.instrument}));
        setJsonData(jsonData);
        setFooterLoaded(true);
        
        if(jsonData.upperLevel && jsonData.lowerLevel){
          let levels=[];
          levels.push(jsonData.upperLevel);
          levels.push(jsonData.lowerLevel);
          setLevelData(levels.sort());
        }
        if(selectedInstrument!=undefined){
          executeAPI(URL.MPCHART,"POST",data);
          console.log("breakDSTR returned= execute api",finalData,instrIndex)
        }
        
  }
 }

 const loadDataLevel=(tempData=undefined)=>{
  console.log("Boundary loadlevelData data=",levelData)
  if(tempData==undefined)
  tempData=levelData;
  let defaultWidth=DEFAULT_PROFILE_WIDTH;
  if(selectedTimeFrame==TIME_FRAME_VALUES.weekly|| selectedTimeFrame==TIME_FRAME_VALUES.weekly_series){
    defaultWidth=DEFAULT_PROFILE_WIDTH_WEEKLY;
  }else if(selectedTimeFrame==TIME_FRAME_VALUES.monthly||selectedTimeFrame==TIME_FRAME_VALUES.monthly_series){
    defaultWidth=DEFAULT_PROFILE_WIDTH_MONTHLY;
  }else if(selectedTimeFrame==TIME_FRAME_VALUES.yearly){
    defaultWidth=DEFAULT_PROFILE_WIDTH_YEARLY;
  }
  // console.log("usersettings=",userSettings.data)
  document.title = "MP Charts - " + selectedInstrument.replace('O_','');
  let data={
    "symbol": selectedInstrument,
    "nDays":0,
    "nProf": Math.round(PROFILE_LOAD_FACTOR*(window.innerWidth/defaultWidth)),
    // "nProf": 10,
    "dstr": "",   //
    "upperLevel":(tempData[0]!=-1 && tempData[1]!=-1 ? tempData[1] : undefined),
    "lowerLevel":(tempData[0]!=-1 && tempData[1]!=-1 ? tempData[0] : undefined),
    "liveData": true,
    "liveOnly": false,
    "liveDataComposite": false,
    "startDate":"",
    // "endDate": "",
    "tf": selectedTimeFrame,
    "max_cmp_days":parseInt(userSettings.data.max_ndays_for_composite),
    "contracts": userSettings.data.data_in_contracts,
    "show_oi_spikes": userSettings.data.show_split_profile_oi_spikes!=undefined?userSettings.data.show_split_profile_oi_spikes:true,
    "show_tpova_markers": userSettings.data.show_split_profile_va_markers!=undefined?userSettings.data.show_split_profile_va_markers:true,
    "krl":userSettings.data.show_krl||userSettings.data.custom_krl_display?1:undefined
  }
  // console.log("chart api data = ",data,selectedInstrument);
  // setState((prevState) => ({
  //   ...prevState,
  //   isLoading:true,
  //   }));
  
  executeAPI(URL.MPCHART,"POST",data);
 }  
 
 

  //execute the data fetch on the component mount
  useEffect(()=>{
   
    // if(selectedInstrument!=undefined && isLoading){
      const auto_saved_links = JSON.parse(localStorage.getItem('auto_saved_links'));
      console.log("usersettings.data autosaved link=>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",instrument,auto_saved_links[instrument]);
      if(props.chartStateData.bookmarkID!=undefined){
        setIsDirty(false);
        loadAutoSaveData(props.chartStateData.bookmarkID)
        return;
      }
      else if(initialData){
        console.log("Reload MPChart container initialData=",initialData)
        loadInitialState(initialData);
        return;
      }
      
      else if(auto_saved_links[instrument]){
        setIsDirty(false);
        loadAutoSaveData(auto_saved_links[instrument])
        return;
      }
      setIsDirty(true);
      setJsonData(undefined);
      setState((prevState) => ({
        ...prevState,
        isJsonLoaded:true,
        isFirtLoadComplete:true}));

     
  },[]);

  useEffect(()=>{
    console.log("useeffect is called for instr and time frame",selectedInstrument, selectedTimeFrame,apiData);
    if(selectedInstrument!=undefined){
      console.log("Breakdstr reset prevDstr=",dstrData)
       
      const auto_saved_links = JSON.parse(localStorage.getItem('auto_saved_links'));
      console.log("MPDateTest",selectedDateTime,apiData)
      console.log("usersettings.data autosaved link=",auto_saved_links[selectedInstrument]);
      if(!selectedDateTime&& apiData!=undefined && apiData.symbol==selectedInstrument &&apiData.tf==selectedTimeFrame &bookmarkData!=undefined){
        setState((prevState) => ({
          ...prevState,
          isLoading:true,
          instrument:selectedInstrument}));
          console.log("usersettings.data autosaved link= 1",auto_saved_links[selectedInstrument]); 
        executeAPI(URL.MPCHART,"POST",apiData);
        setIsDirty(false);
        return;

      }

      else if(!selectedDateTime&&auto_saved_links[selectedInstrument] && selectedTimeFrame =="daily"){
        console.log("usersettings.data autosaved link= 2",auto_saved_links[selectedInstrument]);
        loadAutoSaveData(auto_saved_links[selectedInstrument])
        setIsDirty(false);
        return;
      }
      //save the current symbol in URL if its not in the bookmark view
      if (!searchParams.has('bookmarkID') && props.val==0) {
        setSearchParams({"symbol":selectedInstrument})
      }
      // setJsonData(undefined);
      setState((prevState)=>({
        ...prevState,
        // apiData:undefined,
        bookmarkData:undefined,
        // isJsonLoaded:false,
      }))
      setFooterLoaded(true);
      console.log("usersettings.data autosaved link= 3",auto_saved_links[selectedInstrument]);
      loadData();
      setIsDirty(true);
   
    }
  },[selectedInstrument,selectedTimeFrame,toggleMPLoading,selectedDateTime]);

  

 
  // useState(()=>{
  //   console.log("charttype,state",chartType,state);
  // },[state])
  /**
 * API response handler  
 */  
   useEffect(() => {
    if(loaded){
      console.log("KRL TESTING loaded=true",responseData,responseData?.krl_list);
      if(responseData!=null){
            // if(selectedInstrumentData.krl && userSettings.data.show_krl)
            //  executeAPIKRL(URL.GET_KRL_LIST,"POST",{"symbol":selectedInstrument})
            
            if(responseData.vaMapList!==undefined && responseData.vaMapList.length>0){
              let updateInterval=pollingInterval;
              if(responseData.isPreOpenLive!=undefined && responseData.isPreOpenLive){
                updateInterval=!selectedInstrumentData.slow?reloadInerval:MP_CHART_RELOAD_TIME.SLOW_DATA;
              }
              // responseData.isTradingSessionActive=true;
               setState((prevState) => ({
                  ...prevState,refreshInterval:updateInterval,
                  isPollingAllowed:true,
                  isStatusCheckAllowed:(!responseData.isPreOpenLive),
                  statusCheckPollingInterval:(responseData.isTradingSessionActive?MP_CHART_RELOAD_TIME.FAST_POLLING:MP_CHART_RELOAD_TIME.POLLING_TIME),
                  krlPollingTime:(auth.role.find(el=>el==USER_ROLE_FIELDS.ADMIN) && responseData.isInstrumentLive)?MP_CHART_RELOAD_TIME.FAST_KRL_POLLING_TIME:MP_CHART_RELOAD_TIME.KRL_POLLING_TIME,
                  isJsonLoaded:true,
                  isFirtLoadComplete:true,
                  krlData:responseData.krl_list?responseData.krl_list:[],
                  krlPollingAllowed:selectedInstrumentData.krl,
                  // selectedTimeFrame:(jsonData!=undefined?jsonData.tf:selectedTimeFrame),
                  isTradingSessionActive:responseData.isTradingSessionActive,
                  isLoading:false,isIncrementalData:true,
                  isInstrumentLive:(responseData.isPreOpenLive && responseData.vaMapList[responseData.vaMapList.length-1].dateList[0]==responseData.liveDate), 
                  isLivePollingAllowed:responseData.isPreOpenLive,
                  chartData:responseData}));
            }
            else{
              setState((prevState) => ({
                ...prevState,
                chartRendered:true,
                isLoading:false
              }));
              setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.MP_NO_DATA}`,severity:"info"});
  
            }
            if(responseData.upgrade_notify!=undefined && responseData.upgrade_notify){
              props.openSubscribeModal();
            }
      }
      else if(error!==null){
        console.log("Error data=",error);
        setState((prevState) => ({
          ...prevState,
          isJsonLoaded:true,
          isFirtLoadComplete:true,
          chartRendered:true,
          isLoading:false}));
         
          //if unauthorized then redirec it to login page
          if(error?.response?.status === 401 || error?.response?.status === 403){
            console.log("status received =",error?.response?.status)
            navigate(from, { replace: true });
          }else
          setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});

      }
      reset();
    }
    },[loaded,responseData]);

    useEffect(() => {
      if(loadedKRL){
        if(responseDataKRL!=null){
              // if(responseDataKRL!=undefined && responseDataKRL[selectedInstrument] && responseDataKRL[selectedInstrument].length>0){
                if(responseDataKRL!=undefined && responseDataKRL[selectedInstrument]){
                // console.log("KRL loaded=true",responseDataKRL,responseDataKRL[selectedInstrument]);
               setState((prevState) => ({
                    ...prevState,
                    krlPollingTime:(auth.role.find(el=>el==USER_ROLE_FIELDS.ADMIN))?MP_CHART_RELOAD_TIME.FAST_KRL_POLLING_TIME:MP_CHART_RELOAD_TIME.KRL_POLLING_TIME,
                    krlPollingAllowed:selectedInstrumentData.krl,
                    krlData:responseDataKRL[selectedInstrument]
                  }));
              }
             
        }
        else if(errorKRL!==null){
          console.log("Error data=",errorKRL);
          
          
            // setMsgState({open:true,msg:errorKRL?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
            //if unauthorized then redirec it to login page
            if(errorKRL?.response?.status === 401 || errorKRL?.response?.status === 403){
              console.log("status received =",errorKRL?.response?.status)
              setState((prevState) => ({
                ...prevState,
                krlPollingAllowed:false,
                krlData:[]
              }));
              // props.openSubscribeModal()
              // navigate(from, { replace: true });
            }else
            setMsgState({open:true,msg:errorKRL?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
        }
        resetKRL();
      }
      },[loadedKRL,responseDataKRL]);

      
      useEffect(() => {
        if(loadedKRLPoll){
         
          if(responseDataKRLPoll!=null){
                // if(responseDataKRLPoll!=undefined && responseDataKRLPoll[selectedInstrument] && responseDataKRLPoll[selectedInstrument].length>0){
                  if(responseDataKRLPoll!=undefined && responseDataKRLPoll[selectedInstrument]){
                    // let data=responseDataKRLPoll[selectedInstrument].slice(52,57);
                    // data[0].level=23564;
                    // console.log("KRL TESTING data=",responseDataKRLPoll[selectedInstrument],data);  
                   

                  // console.log("KRL loaded=true",responseDataKRLPoll,responseDataKRLPoll[selectedInstrument]);
                   setState((prevState) => ({
                      ...prevState,
                      krlPollingTime:(auth.role.find(el=>el==USER_ROLE_FIELDS.ADMIN))?MP_CHART_RELOAD_TIME.FAST_KRL_POLLING_TIME:MP_CHART_RELOAD_TIME.KRL_POLLING_TIME,
                      krlPollingAllowed:selectedInstrumentData.krl,
                      krlData:responseDataKRLPoll[selectedInstrument]
                      // krlData:data
                    }));
                }
               
          }
          else if(errorKRLPoll!==null){
            console.log("Error data=",errorKRLPoll);
            
            
              // setMsgState({open:true,msg:errorKRLPoll?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
              //if unauthorized then redirec it to login page
              if(errorKRLPoll?.response?.status === 401 || errorKRLPoll?.response?.status === 403){
                console.log("status received =",errorKRLPoll?.response?.status)
                // navigate(from, { replace: true });
              }
          }
          resetKRLPoll();
        }
        },[loadedKRLPoll,responseDataKRLPoll]);

    
        //polling or live chart update handler
    useEffect(() => {
      //set the ref to current state
      stateRef.current = state;
      
      // console.log("Live use effect called=",stateRef.current,stateRef.current.refreshInterval);
      // const timeout = setInterval(() => {
      //   //if instrument is live, do fetch data based on user role else keep polling every 1min 
      //     let data={
      //       "symbol": stateRef.current.instrument,
      //       "nDays": 0,
      //       "nProf": 1,
      //       "dstr": "",   //
      //       "liveData": true,
      //       "liveOnly": true,
      //       "liveDataComposite": false,
      //       "startDate": "",
      //       "endDate": "",
      //       "tf": selectedTimeFrame,
      //       "max_cmp_days":parseInt(userSettings.data.max_ndays_for_composite),
      //       "show_oi_spikes": userSettings.data.show_split_profile_oi_spikes!=undefined?userSettings.data.show_split_profile_oi_spikes:true,
      //       "show_tpova_markers": userSettings.data.show_split_profile_va_markers!=undefined?userSettings.data.show_split_profile_va_markers:true,
      //       "contracts": userSettings.data.data_in_contracts
      //     }
      //      if(stateRef.current.isPollingAllowed && stateRef.current.isLivePollingAllowed)   {
      //        console.log("calling live mp chart")
      //        executeLive(URL.MPCHART,"POST",data); 
      //      } 
          
      
      // }, stateRef.current.refreshInterval);

      // //market status polling
      // const timeoutStatusCheck=setInterval(() => {
      //   if(stateRef.current.isStatusCheckAllowed && !stateRef.current.isInstrumentLive)   {
      //      executeAPIStatus(URL.CHART_STATUS,"POST",{symbol:stateRef.current.instrument});
      //   }
      // },stateRef.current.statusCheckPollingInterval);

      // const timeoutKRL=setInterval(() => {
      //   if(stateRef.current.instrument && stateRef.current.krlPollingAllowed && (userSettings.data.show_krl|| userSettings.data.custom_krl_display))   {
      //     executeAPIKRLPoll(URL.GET_KRL_LIST,"POST",{"symbol":stateRef.current.instrument,"incremental":1,"bookmark_id":bookmarkData && bookmarkData.category!="AUTO_SAVED"?bookmarkData.id:undefined})
      //   }
      // },stateRef.current.krlPollingTime);
    
      return () => {
        // just to clear the timeout when component unmounts
        // clearInterval(timeout);
        // clearInterval(timeoutStatusCheck);
        // clearInterval(timeoutKRL);
        
      };
    }, [state]);

    useEffect(() => {
      const refreshInterval = setInterval(() => {
        let data={
          "symbol": stateRef.current.instrument,
          "nDays": 0,
          "nProf": 1,
          "dstr": "",   //
          "liveData": true,
          "liveOnly": true,
          "liveDataComposite": false,
          "startDate": "",
          "endDate": "",
          "tf": selectedTimeFrame,
          "max_cmp_days":parseInt(userSettings.data.max_ndays_for_composite),
          "show_oi_spikes": userSettings.data.show_split_profile_oi_spikes!=undefined?userSettings.data.show_split_profile_oi_spikes:true,
          "show_tpova_markers": userSettings.data.show_split_profile_va_markers!=undefined?userSettings.data.show_split_profile_va_markers:true,
          "contracts": userSettings.data.data_in_contracts
        }
         if(stateRef.current.isPollingAllowed && stateRef.current.isLivePollingAllowed)   {
           console.log("calling live mp chart")
           executeLive(URL.MPCHART,"POST",data); 
         } 
        
    
    }, stateRef.current.refreshInterval);
    
      // Store interval ID in ref
      refreshIntervalRef.current = refreshInterval;
    
      return () => {
        clearInterval(refreshIntervalRef.current);
      };
    }, [state.refreshInterval]); // Dependency on the polling interval only

    useEffect(() => {
      const statusCheckInterval = setInterval(() => {
        if (
          stateRef.current.isStatusCheckAllowed &&
          !stateRef.current.isInstrumentLive && (!selectedDateTime || stateRef.current.selectedDateTimeLiveFlag)
        ) {
          executeAPIStatus(URL.CHART_STATUS, "POST", {
            symbol: stateRef.current.instrument,
          });
        }
      }, stateRef.current.statusCheckPollingInterval);
    
      // Store interval ID in ref
      statusCheckIntervalRef.current = statusCheckInterval;
    
      return () => {
        clearInterval(statusCheckIntervalRef.current);
      };
    }, [state.statusCheckPollingInterval]); // Dependency on the polling interval only
    
    // Effect to handle KRL Polling interval
    useEffect(() => {
      const krlPollingInterval = setInterval(() => {
        if (
          stateRef.current.instrument &&
          stateRef.current.krlPollingAllowed &&
          (userSettings.data.show_krl || userSettings.data.custom_krl_display)
        ) {
          executeAPIKRLPoll(URL.GET_KRL_LIST, "POST", {
            symbol: stateRef.current.instrument,
            incremental: 1,
            bookmark_id:
              bookmarkData && bookmarkData.category !== "AUTO_SAVED"
                ? bookmarkData.id
                : undefined,
          });
        }
      }, stateRef.current.krlPollingTime);
    
      // Store interval ID in ref
      krlPollingIntervalRef.current = krlPollingInterval;
    
      return () => {
        clearInterval(krlPollingIntervalRef.current);
      };
    }, [state.krlPollingTime]); // Dependency on the KRL polling time only

    // useEffect(()=>{
    //   // console.log("KRL Test Live use effect called=",stateRef.current,stateRef.current.refreshInterval);
    //    const timeoutKRL=setInterval(() => {
    //     // console.log("KRL Test1",stateRef.current.chartData,stateRef.current.krlPollingTime,stateRef.current.instrument)
    //     if(stateRef.current.instrument && stateRef.current.krlPollingAllowed && (userSettings.data.show_krl|| userSettings.data.custom_krl_display))   {
    //       // console.log("KRL Test2",stateRef.current.instrument)
    //       // if(selectedInstrumentData.krl)
    //       executeAPIKRLPoll(URL.GET_KRL_LIST,"POST",{"symbol":stateRef.current.instrument,"incremental":1,"bookmark_id":bookmarkData && bookmarkData.category!="AUTO_SAVED"?bookmarkData.id:undefined})

    //     }
    //   },stateRef.current.krlPollingTime);

    //   return () => {
    //     // just to clear the timeout when component unmounts
        
    //     clearInterval(timeoutKRL);
        
    //   };

    // },stateRef.current.krlData)


  /**
 * Market live status check response handler
 */  
   useEffect(() => {
    if(loadedStatus){
      if(responseDataStatus!=null){
                //if instrument is live then load the live data else set the status as per the response
                
                if(responseDataStatus.isPreOpenLive!=undefined && responseDataStatus.isPreOpenLive){
                  let data={
                    "symbol": stateRef.current.instrument,
                    "nDays": 0,
                    "nProf": 1,
                    "dstr": "",   //
                    "liveData": true,
                    "liveOnly": true,
                    "liveDataComposite": false,
                    "startDate": "",
                    "endDate": "",
                    "tf": selectedTimeFrame,
                    "max_cmp_days":parseInt(userSettings.data.max_ndays_for_composite),
                    "show_oi_spikes": userSettings.data.show_split_profile_oi_spikes!=undefined?userSettings.data.show_split_profile_oi_spikes:true,
                    "show_tpova_markers": userSettings.data.show_split_profile_va_markers!=undefined?userSettings.data.show_split_profile_va_markers:true,
                    "contracts": userSettings.data.data_in_contracts
                  } 
                  executeLive(URL.MPCHART,"POST",data); 
                }else{
                  //reload the chart once tradinng session goes from active to inactive
                  if(isPollingAllowed && isTradingSessionActive && !responseDataStatus.isTradingSessionActive){
                    console.log("RELOADING MP CHART-------> Trading session is set to false");
                    console.log("reload",searchParams,searchParams.get('bookmarkID'))
                    const auto_saved_links = JSON.parse(localStorage.getItem('auto_saved_links'));
                    if (searchParams.has('bookmarkID')) {
                      loadAutoSaveData(searchParams.get('bookmarkID'));
                      return;
                    }
                   
                    else if(auto_saved_links[selectedInstrument]){
                      // setIsDirty(false);
                      loadAutoSaveData(auto_saved_links[selectedInstrument])
                      return;
                    }
                    // setIsDirty(true);
                    setJsonData(undefined);
                    loadData(); 
                  }
                  else{
                    setState((prevState) => ({
                      ...prevState,
                      isStatusCheckAllowed:(!responseDataStatus.isPreOpenLive),
                      statusCheckPollingInterval:(responseDataStatus.isTradingSessionActive?MP_CHART_RELOAD_TIME.FAST_POLLING:MP_CHART_RELOAD_TIME.POLLING_TIME),
                      krlPollingTime:(auth.role.find(el=>el==USER_ROLE_FIELDS.ADMIN) && responseDataStatus.isInstrumentLive)?MP_CHART_RELOAD_TIME.FAST_KRL_POLLING_TIME:MP_CHART_RELOAD_TIME.KRL_POLLING_TIME,
                      isTradingSessionActive:responseDataStatus.isTradingSessionActive,
                      isInstrumentLive:responseDataStatus.isInstrumentLive,
                      }));
                  }
     
                }
                if(responseDataStatus.upgrade_notify!=undefined && responseDataStatus.upgrade_notify){
                  props.openSubscribeModal();
                }
            
      }
      else if(errorStatus!==null){
        console.log("Error data status check=",errorStatus);

        // setState((prevState) => ({
        //   ...prevState,
        //   isLoading:false}));
          
          if(errorStatus?.response?.status === 401 || errorStatus?.response?.status === 403){
            // console.log("TEST LOGIN chart container 1",errorStatus,errorStatus?.response,errorStatus?.response?.data?.message)
            // console.log("status received =",errorStatus?.response?.status)
            // navigate(from, { replace: true });
            if(props.showLoginPopup){
              // console.log("TEST LOGIN chart container 2")
              props.showLoginPopup(true, errorStatus?.response?.data?.message);
            }
          }
      }
      resetStatus();
    }
    },[loadedStatus,responseDataStatus]);
   
  /**
 * Live data API response handler  
 */  
   useEffect(() => {
    if(loadedLive){
      // console.log("loaded=true live",responseDataLive);
      if(responseDataLive!=null && isPollingAllowed){
        let updateInterval=pollingInterval;
              if(responseDataLive.isPreOpenLive!=undefined && responseDataLive.isPreOpenLive){
                updateInterval=!selectedInstrumentData.slow?reloadInerval:MP_CHART_RELOAD_TIME.SLOW_DATA;
              }
            if(responseDataLive.vaMapList!==undefined && responseDataLive.vaMapList.length>0){
              
              setState((prevState) => ({
                  ...prevState,refreshInterval:updateInterval,
                  isPollingAllowed:isPollingAllowed,
                  isStatusCheckAllowed:(!responseDataLive.isPreOpenLive),
                  statusCheckPollingInterval:(responseDataLive.isTradingSessionActive?MP_CHART_RELOAD_TIME.FAST_POLLING:MP_CHART_RELOAD_TIME.POLLING_TIME),
                  krlPollingTime:(auth.role.find(el=>el==USER_ROLE_FIELDS.ADMIN) && responseDataLive.isInstrumentLive)?MP_CHART_RELOAD_TIME.FAST_KRL_POLLING_TIME:MP_CHART_RELOAD_TIME.KRL_POLLING_TIME,
                  isTradingSessionActive:responseDataLive.isTradingSessionActive,
                  isLoading:false,isIncrementalData:true,isInstrumentLive:responseDataLive.isInstrumentLive,
                  isLivePollingAllowed:responseDataLive.isPreOpenLive,
                  liveData:responseDataLive}));
            }
            else{
              setState((prevState) => ({
                ...prevState,refreshInterval:updateInterval,
                isPollingAllowed:isPollingAllowed,
                isStatusCheckAllowed:(!responseDataLive.isPreOpenLive),
                statusCheckPollingInterval:(responseDataLive.isTradingSessionActive?MP_CHART_RELOAD_TIME.FAST_POLLING:MP_CHART_RELOAD_TIME.POLLING_TIME),
                krlPollingTime:(auth.role.find(el=>el==USER_ROLE_FIELDS.ADMIN) && responseDataLive.isInstrumentLive)?MP_CHART_RELOAD_TIME.FAST_KRL_POLLING_TIME:MP_CHART_RELOAD_TIME.KRL_POLLING_TIME,
                isTradingSessionActive:responseDataLive.isTradingSessionActive,
                isLivePollingAllowed:responseDataLive.isPreOpenLive,
                isInstrumentLive:(responseDataLive.isPreOpenLive && responseDataLive.dateList.length>0 && responseDataLive.dateList[0]==responseDataLive.liveDate)
                }));
               
            }
            if(responseDataLive.upgrade_notify!=undefined && responseDataLive.upgrade_notify){
              props.openSubscribeModal();
            }
      }
      else if(errorLive!==null){
        console.log("Error data live=",errorLive);

        setState((prevState) => ({
          ...prevState,
          isLoading:false}));
          // setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
          // console.log("status received =",errorLive?.response?.status)
          
          if(errorLive?.response?.status === 401 || errorLive?.response?.status === 403){
            console.log("status received =",errorLive?.response?.status)
            // navigate(from, { replace: true });
            if(props.showLoginPopup){
              console.log("TEST LOGIN chart container 1")
              props.showLoginPopup(true, errorLive?.response?.data?.message);
            }
          }
      }
      resetLive();
    }
    },[loadedLive,responseDataLive]);




/**
 * autoclosing of the snackbar msg bar 
 */ 
  const handleClose = (event, reason) => {
    setMsgState({...msgState,open:false});
};

const handleInstrumentChange=(instrument,tpoData,defaultTPO,tradeDates)=>{
  // setSelectedInstrument(undefined);
  setChartRenderedState(false);
  if(props.setChartLoaded){
    props.setChartLoaded(false);
  }
  console.log("instrument change in Market profile =",instrument,tpoData,selectedInstrument,instrument.instr,tradeDates)
  if(selectedInstrument==instrument.instr && (levelData[0]!=-1 && levelData[1]!=-1)){
    // setLevelData([-1,-1]); 
    setSelectedTPO(userSettings.data.tf_tpo_map[instrument.tpo_group]!=undefined?userSettings.data.tf_tpo_map[instrument.tpo_group].daily:userSettings.data.tf_tpo_map["DEFAULT"].daily);
    setSelectedInstrumentTPOData(tpoData);
    setViewState({tpo_only:(userSettings.data.vp_type==VP_TYPE.NONE||userSettings.data.vp_type==VP_TYPE.BACKGROUND||userSettings.data.vp_type==VP_TYPE.ASIDE),vol_tpo_side:(userSettings.data.vp_type==VP_TYPE.ASIDE),only_vol:(userSettings.data.vp_type==VP_TYPE.ALONE),tpo_on_vol:(userSettings.data.vp_type==VP_TYPE.BACKGROUND)})
    setGlobalVolumeVisible(userSettings.data.show_global_vp);
    setVolumeNumberVisible(userSettings.data.show_global_vp_numbers);
    // setTPOBasedVA(!instrument.vp || userSettings.data.va_type==VA_TYPE.TPO_BASED);
    setTPOBasedVA(instrument.vp?userSettings.data.va_type:VA_TYPE.TPO_BASED);
    setProfileVolumeNumberVisible(userSettings?.data?.show_profile_vp_numbers?true:false);
    return;
  }
  // setSelectedInstrumentData(instrument);
  // if(instrument.instr!=selectedInstrument){
    if(true){
      setLevelData([-1,-1]);  
    setJsonData(undefined);
    console.log("live panel resetRightPanel===3")
    props.resetRightPanel();    
  setState((prevState) => ({
    ...prevState,
    selectedTimeFrame:jsonData!=undefined && jsonData.instrument==instrument.instr?jsonData.tf:  TIME_FRAME_VALUES.daily,
    isLoading:true,
    isPollingAllowed:false,   //polling will start when 1st load of new instrument is done
    isStatusCheckAllowed:false,
    instrument:instrument.instr,
    chartData:undefined,
    tradeDates:tradeDates

  }));
  console.log("instrument change in Market profile 2 =",instrument,tpoData,selectedInstrument,instrument.instr)
  setSelectedInstrument(instrument.instr);
  if(instrument.instr==selectedInstrument)
  setToggleMPLoading(!toggleMPLoading);
    
  // setSelectedTPO(tpoData.default_tpo);
  if(jsonData!=undefined && jsonData.instrument==instrument.instr){
    // setIsDirty(false);
    setSelectedTPO(jsonData.tpo)
  }
  else{
    // setSelectedTPO(tpoData.default_tpo)
    // setSelectedTPO(userSettings.data.tf_tpo_map[instrument.tpo_group]!=undefined?userSettings.data.tf_tpo_map[instrument.tpo_group].daily:userSettings.data.tf_tpo_map["DEFAULT"].daily);
    setSelectedTPO(defaultTPO);
    // setSelectedTPO(userSettings.data.tf_tpo_map[instrument.tpo_group]!=undefined?userSettings.data.tf_tpo_map[instrument.tpo_group].daily:tpoData.default_tpo);
    
    // setIsDirty(false);
  }       
  setSelectedInstrumentTPOData(tpoData);
  // setViewState({tpo_only:true,vol_tpo_side:false,only_vol:false,tpo_on_vol:false});
  setViewState({tpo_only:(userSettings.data.vp_type==VP_TYPE.NONE||userSettings.data.vp_type==VP_TYPE.BACKGROUND||userSettings.data.vp_type==VP_TYPE.ASIDE),vol_tpo_side:(userSettings.data.vp_type==VP_TYPE.ASIDE),only_vol:(userSettings.data.vp_type==VP_TYPE.ALONE),tpo_on_vol:(userSettings.data.vp_type==VP_TYPE.BACKGROUND)})
 
  setGlobalVolumeVisible(userSettings.data.show_global_vp);
  setVolumeNumberVisible(userSettings.data.show_global_vp_numbers);
  // setTPOBasedVA(!instrument.vp);
  // setTPOBasedVA(!instrument.vp || userSettings.data.va_type==VA_TYPE.TPO_BASED)
  setTPOBasedVA(instrument.vp?userSettings.data.va_type:VA_TYPE.TPO_BASED);
  setProfileVolumeNumberVisible(userSettings?.data?.show_profile_vp_numbers?true:false);
  setSelectedInstrumentData(instrument);
}
 
  //if instrument is slow and instrument is live then then change the chart update time
}
const handleTpoChange=(val)=>{
   
  
  if(selectedTPO!=val){
    console.log("handleTPO= ",selectedTPO,val);
    console.log("live panel resetRightPanel===4")
    props.resetRightPanel(); 
    setSelectedTPO(val);
    setIsDirty(true);
  }
 
}

const changeTimeFrame=(val)=>{
 
  // props.resetRightPanel();  
  // if(val==selectedTimeFrame || isLoading)
  // return;
  if(selectedTimeFrame==val)
  return;
  setChartRenderedState(false);
   console.log("live panel resetRightPanel===5")
  props.resetRightPanel();  
  setIsDirty(true);
  setJsonData(undefined);
console.log("time frame changed val= ==============================?",val,selectedTimeFrame);
setGlobalVolumeVisible(userSettings.data.show_global_vp);
// setVolumeNumberVisible(false);
setVolumeNumberVisible(userSettings.data.show_global_vp_numbers)
setPerProfileVolumeVisible(false);
setProfileVolumeNumberVisible(userSettings?.data?.show_profile_vp_numbers?true:false);

// setState((prevState) => ({
//   ...prevState,
//   isPollingAllowed:false,
//   isStatusCheckAllowed:false,
//   isLoading:true,
//   selectedTimeFrame:val
// }));

if(val!=TIME_FRAME_VALUES.daily){
  setLevelData([-1,-1]);
}
console.log("Time frame change tpo vals====>",selectedInstrumentData.tpo_group,userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group],selectedInstrumentTPOData,selectedInstrumentTPOData.tf_factor["weekly"],selectedInstrumentTPOData.tf_factor["weekly"]*selectedInstrumentTPOData.default_tpo)
  //set the default tpo based on time frame selected
  if(val==TIME_FRAME_VALUES.daily)
    // setSelectedTPO(selectedInstrumentTPOData.default_tpo);
    // setSelectedTPO(userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group]!=undefined?userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group].daily:userSettings.data.tf_tpo_map["DEFAULT"].daily);
  
   setSelectedTPO(userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group]!=undefined?userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group].daily:selectedInstrumentTPOData.default_tpo.default_tpo);
  
  else if (val==TIME_FRAME_VALUES.weekly || val==TIME_FRAME_VALUES.weekly_series){
      // setSelectedTPO(selectedInstrumentTPOData.tf_factor["weekly"] * selectedInstrumentTPOData.default_tpo)
      // setSelectedTPO(userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group]!=undefined?userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group].weekly:userSettings.data.tf_tpo_map["DEFAULT"].weekly);
      setSelectedTPO(userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group]!=undefined?userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group].weekly:selectedInstrumentTPOData.tf_factor["weekly"] * selectedInstrumentTPOData.default_tpo);

  
  
  }
  else if (val==TIME_FRAME_VALUES.monthly || val==TIME_FRAME_VALUES.monthly_series){
      // setSelectedTPO(selectedInstrumentTPOData.tf_factor["monthly"] * selectedInstrumentTPOData.default_tpo)
      // setSelectedTPO(userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group]!=undefined?userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group].monthly:userSettings.data.tf_tpo_map["DEFAULT"].monthly);
      setSelectedTPO(userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group]!=undefined?userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group].monthly:selectedInstrumentTPOData.tf_factor["monthly"] * selectedInstrumentTPOData.default_tpo);

  
  }
  else if (val==TIME_FRAME_VALUES.yearly){
      // setSelectedTPO(selectedInstrumentTPOData.tf_factor["yearly"]*selectedInstrumentTPOData.default_tpo)
      setSelectedTPO(userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group]!=undefined?userSettings.data.tf_tpo_map[selectedInstrumentData.tpo_group].yearly:userSettings.data.tf_tpo_map["DEFAULT"].yearly);
  
  
  }
  setState((prevState) => ({
    ...prevState,
    isPollingAllowed:false,
    isStatusCheckAllowed:false,
    isLoading:true,
    selectedTimeFrame:val,
    selectedDateTime:null,
    selectedDateTimeLiveFlag:false
  }));
  console.log("time frame changed val End    = ==============================?",val,selectedTimeFrame);
}

const setPollingAllowedVal=(val)=>{
  setState((prevState) => ({
    ...prevState,
    isPollingAllowed:val}));
}


const showVolumeNumber=(val)=>{
  setVolumeNumberVisible(val);
}

const setShowGlobalVolume=(val)=>{
  setGlobalVolumeVisible(val)
}

const showProfileVolumeNumber=(val)=>{
  setProfileVolumeNumberVisible(val);
}

const showProfileVolume=(val)=>{
  setPerProfileVolumeVisible(val)
}
const showTPOOnVolume=(val)=>{
  setTpoOnVolume(val);
}
const showOnlyVolumeProfile=(val)=>{
  setOnlyVolumeProfile(val);
}

const autocenterChart=(val)=>{
  setAutocenter(val);
}

const handleViewStateChange=(state)=>{
  setViewState(state);
  // setIsDirty(true);
}

const handleTPOBasedVA=(val)=>{
  setTPOBasedVA(val);
}

const resetChart=()=>{
setToggleReset(!toggleReset)
}

const unselectProfiles=()=>{
  console.log("unselect profile chart container");
setToggleUnselect(!toggleUnselect)
}

const setRightContainerData=(bshow,metaData,historicalData,selectedProfilesIndex,isLiveSession,isUpdateDataOnly=false)=>{
  if(isUpdateDataOnly){
    props.setRightPanel(bshow,metaData,historicalData,selectedProfilesIndex,isLiveSession,true);
    return;
  }
  else{
  console.log("livepanel setRightContainerData show,meta,historicalData,selectedIndex,isLiveSession",bshow,metaData,historicalData,selectedProfilesIndex,isLiveSession)
    function compareNumbers(a, b) {
      return b - a;
    }
  let sortedArray=[...selectedProfilesIndex]
  sortedArray.sort(compareNumbers);
  console.log("livepanel calling MP setRightPanel");
  // if(props.setRightPanel)
  props.setRightPanel(bshow,metaData,historicalData,sortedArray,isLiveSession);
}
}

const handleVPType=(val)=>{
setVPType(val);
}

const handleFooterLoaded=()=>{
  setFooterLoaded(true);
}

const handleChartChange=(data)=>{
console.log("handleChartChange",data);
setStateData(data);
setChartType(data.data.chart_type);

}

const loadDefaultMPChart=()=>{
  console.log("Load default mp chart start",selectedInstrument)
  // setSelectedInstrument(undefined);
  setState((prevState)=>({
    ...prevState,
    apiData:undefined,
    bookmarkData:undefined,
    instrument:undefined,
    chartData:undefined,
    isPollingAllowed:false,
    isStatusCheckAllowed:false,
    isFirtLoadComplete:false,

    // isJsonLoaded:false,
  }))
  setJsonData(undefined);
  console.log("Load default mp chart end",selectedInstrument)
  if (searchParams.has('bookmarkID') && props.val==0) {
    searchParams.delete('bookmarkID');
    setSearchParams({"symbol":selectedInstrument})
    // setSearchParams(searchParams);
  }
  setToggleMPLoading(!toggleMPLoading);
  // loadData();

}

useEffect(()=>{
  selectedInstrumentRef.current=selectedInstrument;
console.log("useeffect selectedinstrument",selectedInstrument,selectedInstrumentRef.current);
},[selectedInstrument])

const loadBookmark=(bookmark)=>{
  setState((prevState)=>({
    ...prevState,
    apiData:undefined,
    bookmarkData:undefined,
    isFirtLoadComplete:false,
    // isJsonLoaded:false,
    chartData:undefined,
    isPollingAllowed:false,
    isStatusCheckAllowed:false
  }))
  setIsDirty(false)
  setFooterLoaded(false)
  loadAutoSaveData(bookmark.id);
  if(props.val==0)
  setSearchParams({"bookmarkID": bookmark.id})
}

const deleteAutosavedBookmark=()=>{
  setOpenAutosaveDeleteDialog(false);
  const auto_saved_links=JSON.parse(localStorage.getItem('auto_saved_links'));
  const id =auto_saved_links[selectedInstrument];
  if(id!=undefined && id!=null){
    let data={
      "id": id
    }
  
    executeAPIDelete(URL.DELETE_BOOKMARK,"DELETE",data)
}

}

const cancelAutosaveDelete=()=>{
  setOpenAutosaveDeleteDialog(false)
}

const clearAutosave=()=>{
  setOpenAutosaveDeleteDialog(true);
}



//delete api response handler
useEffect(()=>{
  if(loadedDelete){
    console.log("Delete response ========>",responseDataDelete);
    if(responseDataDelete!=null){
      setMsgState({open:true,msg:`${TEXT_MSGS.CLEAR_AUTOSAVED_BOOKMARK_SUCCESS}`,severity:"success"});
      
      // let updatedData= bookmarkData.filter(item => item.id!=deletedId);
      let auto_saved_links=JSON.parse(localStorage.getItem('auto_saved_links'));
      delete auto_saved_links[selectedInstrument];
      localStorage.setItem('auto_saved_links', JSON.stringify(auto_saved_links));
      setState((prevState)=>({
        ...prevState,
        apiData:undefined,
        bookmarkData:undefined,
      }))
      setJsonData(undefined);
      
      setReloadInstrument(selectedInstrument);
      setSelectedInstrument(undefined)
 
     }else if(errorDelete!==null){
      
      if(errorDelete?.response?.status === 401 || errorDelete?.response?.status === 403){
        console.log("status received =",errorDelete?.response?.status)
        navigate(from, { replace: true });
      }else
      setMsgState({open:true,msg:error?.response?.data?.message ?? `${TEXT_MSGS.NETWORK_ERROR_MSG}`,severity:"info"});
    }
    resetDelete();
    
  }

},[loadedDelete,responseDataDelete])

const handleDirty=(val)=>{
  setIsDirty(val);
}

const updateBookmarkData=(data)=>{
  setState((prevState)=>({
    ...prevState,
    // apiData:undefined,
    bookmarkData:data,
    // isFirtLoadComplete:false,
    isJsonLoaded:true,
    // chartData:undefined,
    // isPollingAllowed:false
  }))
  setJsonData(undefined)
  
}

const showPriceTable=()=>{
  console.log("showTable=",showTable);
    setShowTable(true)
}

const showSubscribeModal=(isCustom,msg)=>{
  
  props.openSubscribeModal(isCustom,msg);
}

const setlevelBoundary=(data)=>{
  console.log("Boundary setlevelBoundary data=",data);
  let boundary=[];
  boundary.push(data[0]);
  boundary.push(data[1]);

  setLevelData(boundary.sort());
  setState((prevState)=>({
    ...prevState,
    isLoading:true,
    apiData:undefined,
    bookmarkData:undefined,
    isPollingAllowed:false,   //polling will start when 1st load of new instrument is done
    isStatusCheckAllowed:false,
    chartData:undefined,
  }))
  setJsonData(undefined);
  loadDataLevel(boundary);
  
  
  // setReloadInstrument(selectedInstrument);
  // setSelectedInstrument(undefined)
}

const resetlevelBoundary=(data)=>{
  console.log("Boundary resetlevelBoundary data=",data);
  let boundary=[];
  boundary.push(data[0]);
  boundary.push(data[1]);

  if(levelData[0]!=-1 && levelData[1]!=-1){
    setLevelData(boundary.sort());
  setState((prevState)=>({
    ...prevState,
    isLoading:true,
    apiData:undefined,
    bookmarkData:undefined,
    isPollingAllowed:false,   //polling will start when 1st load of new instrument is done
    isStatusCheckAllowed:false,
    chartData:undefined,
  }))
  setJsonData(undefined);
  // loadDataLevel(boundary);
  setToggleMPLoading(!toggleMPLoading);
}else{
  setLevelData(boundary.sort());
}
  
  
  // setReloadInstrument(selectedInstrument);
  // setSelectedInstrument(undefined)
}
useEffect(()=>{
  console.log("Boundary levelData useeffect chart container=",levelData);
  // loadDataLevel();
 
 },[levelData])

 const openSunscriptionModal=(isCustom,msg)=>{
  props.openSubscribePropModal(isCustom,msg);

 }

 const setChartRenderedState=(val)=>{
  setState((prevState)=>({
    ...prevState,
    chartRendered:val
  }))
  console.log("HelloTest val 1=",props.setChartLoaded)
  if(props.setChartLoaded){
    console.log("HelloTest val 1=",val)
    const timeout = setTimeout(() => {
      props.setChartLoaded(val);
  }, 1000);
    
  }
 }

 const openSettings=()=>{
  setShowSettings(true);
 }

 const cancelSettings=()=>{
  setShowSettings(false);
 }

 const saveSettings=(settings)=>{
  setShowSettings(false);
  console.log("MP Settings save settings in chart container",settings);
  setUserSettings(settings);
 }

 useEffect(()=>{
  console.log("MP Settings useEffect called");
 },[userSettings])

  const changeSelectedDateTime = (dateTime) => {
    if (selectedDateTime != dateTime) {
      setChartRenderedState(false);

      props.resetRightPanel();
      setIsDirty(true);
      setJsonData(undefined);
      setGlobalVolumeVisible(userSettings.data.show_global_vp);
      setVolumeNumberVisible(userSettings.data.show_global_vp_numbers)
      setPerProfileVolumeVisible(false);
      setProfileVolumeNumberVisible(userSettings?.data?.show_profile_vp_numbers ? true : false);

      setState((prevState) => ({
        ...prevState,
        isPollingAllowed: false,
        isStatusCheckAllowed: false,
        isLoading: true,
        selectedDateTime: dateTime,
      }));

    }
  }

  const loadLiveData = () => {
    if (selectedDateTime) {
      console.log("DateTestNew loadlivedate")
      setState((prevState) => ({
        ...prevState,
        isPollingAllowed: true,
        liveData: undefined,
        selectedDateTimeLiveFlag: true,
      }));

      let data = {
        "symbol": stateRef.current.instrument,
        "nDays": 0,
        "nProf": 1,
        "dstr": "",   //
        "liveData": true,
        "liveOnly": true,
        "liveDataComposite": false,
        "startDate": "",
        "endDate": "",
        "tf": selectedTimeFrame,
        "max_cmp_days": parseInt(userSettings.data.max_ndays_for_composite),
        "show_oi_spikes": userSettings.data.show_split_profile_oi_spikes != undefined ? userSettings.data.show_split_profile_oi_spikes : true,
        "show_tpova_markers": userSettings.data.show_split_profile_va_markers != undefined ? userSettings.data.show_split_profile_va_markers : true,
        "contracts": userSettings.data.data_in_contracts
      }
      executeLive(URL.MPCHART, "POST", data);
    }
  }
  return (
    <>
     {chartType==CHART_TYPE.MARKET_PROFILE?
      <>
      {isJsonLoaded?
      <MPChartHeaderSettings chartStateData={props.chartStateData} resetRightPanel={props.resetRightPanel} userSettings={userSettings} symbol={instrument} selectedTimeFrame={selectedTimeFrame}
        instrumentChange={handleInstrumentChange} tpo={selectedTPO} tpoChange={handleTpoChange} jsonData={jsonData} bookmarkData={bookmarkData} loadDefaultMPChart={loadDefaultMPChart}
        basicUser={props.basicUser} proUser={props.proUser} saveChart={()=>setToggleSave(!toggleSave)} saveBookmark={()=>setToggleBookmark(!toggleSaveBookmark)} clearAutosave={clearAutosave}
         reloadInstrument={reloadInstrument} handleDirty={handleDirty} isDirty={isDirty} showTable={showTable} levelData={levelData} openSunscriptionModal={openSunscriptionModal}
         disabled={!chartRendered}  selectedDateTime={selectedDateTime} changeSelectedDateTime={changeSelectedDateTime} tradeDates={mpTradeDates} 
        />
        :<></>
      }
      <div style={{paddingTop:`${CONSTANTS.FIXED_HEADER_HEIGHT}px`,width:"100%",height:"100%"}}>
       {isLoading? 
          <div  style={{textAlign:"center", width:"100%",height:"100%"}}><CircularProgress sx={{marginTop:"20%"}}/>
          </div>:
          <>
          {chartData!=undefined && footerLoaded?
          <div >
          {/* {!showTable? */}

          <MPChart key={props.key} val={props.val} chartScreenType={props.chartScreenType} id={props.id} chartStateData={props.chartStateData} userSettings={userSettings} liveData={liveData}
           volumeNumberVisible={volumeNumberVisible} selectedInstrumentData={selectedInstrumentData} globalVolumeVisible={globalVolumeVisible}
            selectedInstrumentTPOData={selectedInstrumentTPOData} chartData={chartData} isInstrumentLive={isInstrumentLive} 
          instrument={selectedInstrument} tpo={selectedTPO} selectedTimeFrame={selectedTimeFrame}
          setPollingAllowedVal={setPollingAllowedVal} isTradingSessionActive={isTradingSessionActive}
          profileVolumeVisible={perProfilelVolumeVisible}
          isPollingAllowed={isPollingAllowed}
          profileVolumeNumberVisible={perProfileVolumeNumberVisible}
          onlyVolumeProfile={onlyVolumeProfile}
          tpoOnVolume={tpoOnVolume}
          viewState={viewState}
          tpoBasedVA={tpoBasedVA}
          resetChart={toggleReset}  
          unselectProfiles={toggleUnselect}
          max_cmp_days={parseInt(userSettings.data.max_ndays_for_composite)}
          contracts={userSettings.data.data_in_contracts}
          locale_string={userSettings.data.number_format_locale}
          setRightContainerData={setRightContainerData}
          resetRightPanel={props.resetRightPanel}
          basicUser={props.basicUser}
          saveChart={toggleSave}
          saveBookmark={toggleSaveBookmark}
          vpType={vpType}
          bookmarkData={bookmarkData}
          handleDirty={handleDirty}
          updateBookmarkData={updateBookmarkData}
          showPriceTable={showPriceTable}
          showTable={showTable}
          openSubscribeModal= {showSubscribeModal}
          setlevelBoundary={setlevelBoundary}
          resetlevelBoundary={resetlevelBoundary}
          levelData={levelData}
          krlData={krlData}
          npocAllowed={npocAllowed}
          dstrData={dstrData}
          proUser={props.proUser}
          autoCenter={autoCenter}
          boundClass={props.boundClass}
          tradeDates={tradeDates}
          setChartRenderedState={setChartRenderedState}
          selectedProfilesIndex={props.selectedIndexes}
          chartRendered={chartRendered}
          showLoginPopup={props.showLoginPopup}
          saveChartState={props.saveChartState}
          loadLiveData={loadLiveData}
          selectedDateTime={selectedDateTime}
          mpTradeDates={UIData.tradeDates}
          />
          {/* {showTable?
          <PRICE_TABLE>

          </PRICE_TABLE>:
          <></>
          } */}
         
          </div>
          :<>
          
          </>
          }
          </>
       }
        {/* <Divider orientation="vertical" sx={{width:"100%",height:'2px'}} /> 
        <MPChartRightPanel/> */}
        {!isLoading && chartData!=undefined && isJsonLoaded? 
          <MPChartFooter chartType={chartType}chartStateData={props.chartStateData} userSettings={userSettings} selectedInstrumentData={selectedInstrumentData} changeTimeFrame={changeTimeFrame}setAutocenter={autocenterChart} 
          setShowGlobalVolume={setShowGlobalVolume}  timeFrame={selectedTimeFrame}
          showVolumeNumber={showVolumeNumber}openStockChart={openStockChart}
          showPerProfileVolumeNumber={showProfileVolumeNumber}
          showPerProfilelVolume={showProfileVolume}
          showTPOOnVolume={showTPOOnVolume}
          showOnlyVolumeProfile={showOnlyVolumeProfile}
          handleViewStateChange={handleViewStateChange}
          showTPOBasedVA={handleTPOBasedVA}
          selectedInstrument={selectedInstrument}
          openLineChart={openLineChart}
          resetChart={resetChart}
          unselectProfiles={unselectProfiles}
          basicUser={props.basicUser}
          resetRightPanel={props.resetRightPanel}
          jsonData={jsonData} 
          bookmarkData={bookmarkData}
          handleVPType={handleVPType}
          handleFooterLoaded={handleFooterLoaded}
          loadBookmark={loadBookmark}
          auth={auth}
          showPriceTable={showPriceTable}
          showTable={showTable}
          handleDirty={handleDirty}
          openSubscribeModal= {showSubscribeModal}
          openSunscriptionModal={openSunscriptionModal}
          tpoBasedVA={tpoBasedVA}
          openOFChart={openOFChart}
          openMPChart={openMPChart}
          isEliteUser={isEliteUser}
          openSettings={openSettings}/>
         :
         <></>}
      </div>
        {openAutosaveDeleteDialog?
      <ConfirmActionDialog open={true} cancelHandler={cancelAutosaveDelete} saveHandler={deleteAutosavedBookmark} 
          title={TEXT_MSGS.CLEAR_AUTOSAVED_TITLE} description={TEXT_MSGS.CLEAR_AUTOSAVED_BOOKMARK}></ConfirmActionDialog>    :
      <></>
      }  
      <Snackbar onClose={handleClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}} sx={{top:"48px"}} open={open} autoHideDuration={SNACKBAR_AUTO_HIDE_DURATION} >
          <Alert  severity={severity} sx={{ width:{mobile: '80%',tablet:"70%",laptop:"40%" }}}>
            {msg}
          </Alert>  
       </Snackbar>
    </>:
    <>
   
     {chartType==CHART_TYPE.OF_CHART?
     <OrderflowContainer 
     tabData={tabData} showLoginPopup={props.showLoginPopup} id={props.id} setChartLoaded={props.setChartLoaded} val={props.val} boundClass={props.boundClass} chartScreenType={props.chartScreenType} setChartType={props.setChartType} chartStateData={props.chartStateData} symbol={selectedInstrument} 
     saveChartState={props.saveChartState} resetRightPanel={props.resetRightPanel} setRightPanel={props.setRightPanel} openSubscribeModal={props.openSubscribePropModal}  basicUser={props.basicUser}  proUser={props.proUser}>
      
     </OrderflowContainer>:
     <></>
    }
    
    {chartType==CHART_TYPE.LINE_CHART?
    // <LineChart id={props.id} val={props.val} stateData={stateData} symbol={selectedInstrument} nDays={userSettings.data.default_lc_ndays}></LineChart>
    <>
    {isEliteUser?
    <LineChartContainerNew
    key={0}
    val={props.val}
    id={props.id}
    symbol={selectedInstrument}
    nDays={userSettings.data.default_lc_ndays}
    boundClass={props.boundClass}
    setChartType={props.setChartType}
    resetRightPanel={props.resetRightPanel}
    setRightPanel={props.setRightPanel}
    chartScreenType={props.chartScreenType}
    basicUser={props.basicUser}
    openSubscribeModal={props.openSubscribePropModal} 
    // openSubscribePropModal={showSubscribeProModal}
    proUser={props.proUser}
    chartStateData={props.chartStateData}
    stateData={stateData}
    setChartLoaded={props.setChartLoaded}
    saveChartState={props.saveChartState}
    tabData={tabData}
  />:
    <LineChartContainer
    key={0}
    val={props.val}
    id={props.id}
    symbol={selectedInstrument}
    nDays={userSettings.data.default_lc_ndays}
    boundClass={props.boundClass}
    setChartType={props.setChartType}
    resetRightPanel={props.resetRightPanel}
    setRightPanel={props.setRightPanel}
    chartScreenType={props.chartScreenType}
    basicUser={props.basicUser}
    openSubscribeModal={props.openSubscribePropModal} 
    // openSubscribePropModal={showSubscribeProModal}
    proUser={props.proUser}
    chartStateData={props.chartStateData}
    stateData={stateData}
    setChartLoaded={props.setChartLoaded}
    saveChartState={props.saveChartState}
    tabData={tabData}
  ></LineChartContainer>
    }
  </>
    :
    <></>}
    

    {chartType==CHART_TYPE.HV_CHART?
    <StockChartHV id={props.id} stateData={stateData} symbol={selectedInstrument} nDays={userSettings.data.default_lc_ndays}></StockChartHV>:
    <></>}
     {chartType==CHART_TYPE.BAR_CHART?
    // <BarChart id={props.id} stateData={stateData} symbol={selectedInstrument} nDays={userSettings.data.default_lc_ndays}></BarChart>
    <BarChartContainer
    key={0}
    val={props.val}
    id={props.id}
    symbol={selectedInstrument}
    nDays={userSettings.data.default_lc_ndays}
    boundClass={props.boundClass}
    setChartType={props.setChartType}
    resetRightPanel={props.resetRightPanel}
    setRightPanel={props.setRightPanel}
    chartScreenType={props.chartScreenType}
    basicUser={props.basicUser}
    openSubscribeModal={props.openSubscribePropModal} 
    // openSubscribePropModal={showSubscribeProModal}
    proUser={props.proUser}
    chartStateData={props.chartStateData}
    stateData={stateData}
    setChartLoaded={props.setChartLoaded}
    saveChartState={props.saveChartState}
    tabData={tabData}
  />
    :
    <></>}

     {chartType!=CHART_TYPE.OF_CHART && chartType!=CHART_TYPE.LINE_CHART && chartType!=CHART_TYPE.BAR_CHART?
    <MPChartFooter chartType={chartType} chartStateData={props.chartStateData} userSettings={userSettings} selectedInstrumentData={selectedInstrumentData} changeTimeFrame={changeTimeFrame}setAutocenter={autocenterChart} 
          setShowGlobalVolume={setShowGlobalVolume}  timeFrame={selectedTimeFrame}
          showVolumeNumber={showVolumeNumber}openStockChart={openStockChart}
          showPerProfileVolumeNumber={showProfileVolumeNumber}
          showPerProfilelVolume={showProfileVolume}
          showTPOOnVolume={showTPOOnVolume}
          showOnlyVolumeProfile={showOnlyVolumeProfile}
          handleViewStateChange={handleViewStateChange}
          showTPOBasedVA={handleTPOBasedVA}
          selectedInstrument={selectedInstrument}
          openLineChart={openLineChart}
          basicUser={props.basicUser}
          handleVPType={handleVPType}
          handleFooterLoaded={handleFooterLoaded}
          handleChartChange={handleChartChange}
          auth={auth}
          showPriceTable={showPriceTable}
          showTable={showTable}
          openSubscribeModal= {showSubscribeModal}
          openOFChart={openOFChart}
          openMPChart={openMPChart}
          isEliteUser={isEliteUser}
          openSettings={openSettings}/>
          :<></>
     }
    </>
    }   
{showSettings?
     <MPSettings open={true} proUser={props.proUser} selectedInstrumentData={selectedInstrumentData} instrument={selectedInstrument} cancelHandler={cancelSettings} saveHandler={saveSettings} ></MPSettings>    :
     <></>
    }
       
    </>
       
   
   
  )
}

export default ChartContainer




