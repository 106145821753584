import { Rnd } from "react-rnd";
import React,{useCallback,useEffect, useState} from "react";
import { Box,Tabs,Tab,Divider,Tooltip ,Typography,Container,Select as MUISelect,MenuItem} from '@mui/material';
import {useTheme} from "@mui/material";
import { RIGHT_PANEL_CONTAINER_WIDTH,CHART_COLORS } from '../../../common/utility/mp_chart_constants';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RestartAltOutlinedIcon from '@mui/icons-material/FilterCenterFocusOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import FormControl from '@mui/material/FormControl';
import { REPLAY_SPEED, REPLAY_SPEED_OPTIONS } from "../../../common/utility/constant";
import StopIcon from '@mui/icons-material/Stop';

const style = {
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #285fbd",
    background: "#0d203fdd",
    zIndex:99999,
    borderRadius:"20px"
  };


  const OFFABModal = (props) => {
    const [width,setWidth]=useState(260);
    const [height,setHeight]=useState(210);
    const theme=useTheme();  
    // const []
    const [xCord,setXCord]=useState(props.selectedDateTime?props.chartStateData.width/2-120:props.chartStateData.width/2-20);
    const [yCord,setYCord]=useState(props.chartStateData.height-props.bottomMargin-126);
    const [isDragging, setDragging] = useState(false)
    // const [xCord,setXCord]=useState(20);
    // const [yCord,setYCord]=useState(24);
    const [isTouchDevice,setIsTouchDevice]=useState(false);
    const [interval, setInterval] = useState(REPLAY_SPEED.DEFAULT);
    const [speed, setSpeed] = useState(REPLAY_SPEED.DEFAULT);
  
    useEffect(()=>{
      function hasTouch() {
        return (('ontouchstart' in window) ||       // html5 browsers
                (navigator.maxTouchPoints > 0) ||   // future IE
                (navigator.msMaxTouchPoints > 0));  // current IE10
    }
    // if (!hasTouch()) alert('Sorry, need touch!');
    setIsTouchDevice(hasTouch());
    },[])

    useEffect(()=>{
      console.log("OFHelp props=",props)
      // setWidth(props.selectedDateTime?460:260)
    },[props])

    useEffect(()=>{
        // console.log("OFLiveModal props=",props)
        setXCord(props.selectedDateTime?props.chartStateData.width/2-120:props.chartStateData.width/2-20);
        setYCord(props.chartStateData.height-props.bottomMargin-126);
    },[props.chartStateData,props.bottomMargin])
  
    const handleClick = (e) => {
        e.preventDefault();
        // console.log("Drag start handle click=",isDragging);
        if (isDragging === true) {
          return
        }
    
        props.recenterChart();
      }


   

      const handleHelpClick = (e) => {
        e.preventDefault();
        // console.log("Drag start handle click=",isDragging);
        if (isDragging === true) {
          return
        }
    
        props.showHelp();
      }
   
      const handleClickPlayPause = (e,start) => {
        e.preventDefault();
        // console.log("Drag start handle click=",isDragging);
        if (isDragging === true) {
          return
        }
    
        props.changeReplay(start);
      }
  
      const handleExitReplayClick = (e) => {
        e.preventDefault();
        // console.log("Drag start handle click=",isDragging);
        if (isDragging === true) {
          return
        }
    
        props.exitReplay();
      }
  
      const handleSpeedChange = (event) => {
        console.log("handleSpeedChange",event.target.value)
        const selectedSpeed = event.target.value;
        // setSpeed(selectedSpeed);
        // setInterval(1000 / selectedSpeed); // Convert speed to milliseconds
        // props.changeInterval(1000 / selectedSpeed);
        setSpeed(selectedSpeed);
        setInterval(selectedSpeed); // Convert speed to milliseconds
        props.changeInterval(selectedSpeed);
      };
       
   
  
   
   
    
    return (
      <>
    <Rnd
        style={style}
        resizeHandleStyles={{
          topRight:{cursor: 'default'},
          bottomRight:{cursor: 'default'},
          topLeft:{cursor: 'default'},
          bottomLeft:{cursor: 'default'},
          right:{cursor: 'default'},
          left:{cursor: 'default'},
          top: {cursor: 'default'},
          bottom:{cursor: 'default'},

        }}
        size={{ width: width, height: height }}
        position={{ x: xCord, y: yCord }}
        onDrag={(e, d) => {
            // console.log("Drag start =",isDragging);
            setDragging(true);

        }}
        onDragStop={(e, d) => {
        //   this.setState({ xCord: d.x, yCord: d.y });
        setXCord(d.x);
        setYCord(d.y);
        
        const timeout = setTimeout(() => {
            // console.log("Drag start End =",isDragging);
            setDragging(false);
        }, 100);
        
        }}
        onResizeStop={(e, direction, ref, delta, position) => {
        //   this.setState({
        //     width: ref.style.width,
        //     height: ref.style.height,
        //     ...position
        //   });
        // setWidth(width);
        setWidth(ref.style.width);
        setHeight(ref.style.height);
        setXCord(position.x);
        // setYCord(position.y);

        }}
        minWidth={90}
        maxWidth={props.selectedDateTime?255:90}
        minHeight={40}
        maxHeight={40}
        bounds={props.boundClassName}
        cancel=".cancel"
      >

{/* <Box sx={{display:"flex",flexDirection:'column',width:"100%",height:"100%",overflowY:'hidden',overflowX:"hidden"}}>  */}
<Box sx={{display:"flex",flexDirection:'row',width:"100%",height:"100%",overflowY:'hidden',overflowX:"hidden"}}> 

{props.selectedDateTime?
  <>
  {props.replayState.play==false?
  <Tooltip title="Start Replay">
  <div className={isTouchDevice?"cancel":"dummy"} onClick={(e) => handleClickPlayPause(e,true)}>
                <PlayCircleOutlineIcon 
                  // onClick={(e)=>handleClick(e)}
                  sx={{marginLeft:"10px",marginTop:"7px",marginRight:"10px", height:24,width:24,cursor:'pointer'}}>
                </PlayCircleOutlineIcon>
                </div>
              </Tooltip> :
              <Tooltip title="Pause Replay">
              <div className={isTouchDevice?"cancel":"dummy"} onClick={(e) => handleClickPlayPause(e,false)}>
                            <PauseCircleIcon 
                              // onClick={(e)=>handleClick(e)}
                              sx={{marginLeft:"10px",marginTop:"7px",marginRight:"10px", height:24,width:24,cursor:'pointer'}}>
                            </PauseCircleIcon>
                            </div>
                          </Tooltip> 
  }
  
  <FormControl
    variant="standard"
    size="small"
    sx={{ marginLeft: "2px", minWidth: "80px", height: "58%",marginTop:"5px" }}
    onMouseDown={(e) => e.stopPropagation()} // Prevent react-rnd interference
  >
    <MUISelect
      labelId="interval-speed-label"
      value={speed}
      sx={{ textAlign: "center", width: 80, paddingTop: "4px" }}
      onChange={(e) => handleSpeedChange(e)}
      renderValue={(value) => {
        const selectedOption = REPLAY_SPEED_OPTIONS.find(
          (option) => option.value === value
        );
        return selectedOption ? selectedOption.name : "";
      }}
      MenuProps={{
        disableScrollLock: true, // Prevent scroll-locking issues
        PaperProps: {
          onClick: (e) => e.stopPropagation(), // Fix event bubbling inside the menu
        },
      }}
    >
      {REPLAY_SPEED_OPTIONS.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </MUISelect>
  </FormControl>


  <Tooltip title="Stop & Exit Replay">
  <div className={isTouchDevice?"cancel":"dummy"} onClick={(e) => handleExitReplayClick(e)}>
                <StopIcon 
                  // onClick={(e)=>handleClick(e)}
                  sx={{marginLeft:"10px",marginTop:"7px",marginRight:"10px", height:24,width:24,cursor:'pointer',color:"red"}}>
                </StopIcon>
                </div>
              </Tooltip> 
  </>:
  <>
  </>
}

<Tooltip title="Reset Zoom / Recenter (keyboard shortcut: r)">
<div className={isTouchDevice?"cancel":"dummy"} onClick={(e) => handleClick(e)}>
              <RestartAltOutlinedIcon 
                // onClick={(e)=>handleClick(e)}
                sx={{marginLeft:"10px",marginTop:"7px",marginRight:"10px", height:24,width:24,cursor:'pointer'}}>
              </RestartAltOutlinedIcon>
              </div>
            </Tooltip> 

            <Tooltip title="Zoom / Pan Help">
<div className={isTouchDevice?"cancel":"dummy"} onClick={(e) => handleHelpClick(e)}>
              <HelpOutlineIcon 
                // onClick={(e)=>handleClick(e)}
                sx={{marginLeft:"7px",marginTop:"7px",height:24,width:24,cursor:'pointer'}}>
              </HelpOutlineIcon>
              </div>
            </Tooltip> 
            


</Box>
      </Rnd>
        
      </>
    );
  };
  
  export default OFFABModal;
  