import React, { useState, useEffect, useMemo } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { TRADING_HOURS } from "../../../common/utility/constant";

//OF date time picker
const TradeDatesPicker = (props) => {
  const [tradeDates, setTradeDates] = useState(props.tradeDates || []);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(props.isDatePickerOpen);
  const [liveState, setLiveState] = useState({
    isLive: false,
    liveDate: null,
    liveTime: undefined,
    ...props.liveState,
  });
  const [selectedDate, setSelectedDate] = useState(props.selectedDate||null);
  const [okPressed,setOKPressed]=useState(false);

  useEffect(()=>{
    setSelectedDate(props.selectedDate);
   },[props.selectedDate])
   
  // Handle DatePicker open/close state
  const handleDatePickerOpenChange = (open) => {
    console.log("EnterKey DatePicker open state changed:", open);
    setIsDatePickerOpen(open);
    props.setIsDatePickerOpen(open);
   
    //handling of enter key press on datepicker overlay to trigger OK button click manually
    if (open) {
      // Attach keydown event to the DatePicker popup when it opens
      setTimeout(() => {
        const overlay = document.querySelector(".ant-picker-dropdown");
        if (overlay) {
          overlay.addEventListener("keydown", handleKeyDown);
        }
      }, 0);
    } else {
      if(!okPressed) setSelectedDate(null);
      // Cleanup the event listener when the DatePicker popup closes
      const overlay = document.querySelector(".ant-picker-dropdown");
      if (overlay) {
        overlay.removeEventListener("keydown", handleKeyDown);
      }
    }
  };

  // Update liveState when the DatePicker is closed
  useEffect(() => {
    if (!isDatePickerOpen) {
      setLiveState({
        isLive: props.liveState.isLive,
        liveDate: props.liveState.liveDate || null,
        liveTime: props.liveState.liveTime,
      });
    }
  }, [props.liveState, isDatePickerOpen]);

  // Memoized function to disable specific dates
  const disabledDate = useMemo(() => {
    return (current) => {
      if (liveState.isLive && liveState.liveDate && liveState.liveTime) {
        const livedate = dayjs(liveState.liveDate, "DD-MM-YYYY").format("YYYY-MM-DD");
        if (!tradeDates.includes(livedate)) {
          tradeDates.push(livedate);
        }
      }

      const formattedCurrentDate = current ? current.format("YYYY-MM-DD") : null;
      return !tradeDates.includes(formattedCurrentDate);
    };
  }, [liveState, tradeDates]);

  // Memoized function to handle disabled times
  const memoizedDisabledDateTimeNew = useMemo(() => {
    const targetDate = liveState.isLive && liveState.liveDate && liveState.liveTime ? liveState.liveDate : null;
    const lastTime = liveState.isLive && liveState.liveDate && liveState.liveTime ? liveState.liveTime : null;

    return (selectedDate) => {
      const selectedDayjs = dayjs(selectedDate);
      const targetDayjs = targetDate ? dayjs(targetDate, "DD-MM-YYYY") : null;

      return {
        disabledHours: () => {
          if (targetDayjs && selectedDayjs.isSame(targetDayjs, "day")) {
            if (lastTime) {
              const [lastHour] = lastTime.split(":").map(Number);
              return Array.from({ length: 24 }, (_, i) => i).filter((hour) => hour < TRADING_HOURS.START_HOUR || hour > lastHour);
            }
          }
          return Array.from({ length: 24 }, (_, i) => i).filter((hour) => hour < TRADING_HOURS.START_HOUR || hour > TRADING_HOURS.END_HOUR);
        },
        disabledMinutes: (selectedHour) => {
          if (targetDayjs && selectedDayjs.isSame(targetDayjs, "day")) {
            if (lastTime) {
              const [lastHour, lastMinute] = lastTime.split(":").map(Number);
              if (selectedHour === lastHour) {
                return Array.from({ length: 60 }, (_, i) => i).filter((minute) => minute > lastMinute);
              }
            }
          }

          if (selectedHour === TRADING_HOURS.START_HOUR) {
            return Array.from({ length: 60 }, (_, i) => i).filter((minute) => minute < TRADING_HOURS.START_MINUTE);
          }
          if (selectedHour === TRADING_HOURS.END_HOUR) {
            return Array.from({ length: 60 }, (_, i) => i).filter((minute) => minute > TRADING_HOURS.END_MINUTE);
          }
          return [];
        },
        disabledSeconds: () => [],
      };
    };
  }, [liveState]);

  // Handle DatePicker change
  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log("OFDatepicker 1 Selected date:", date ? date.format("DD-MM-YYYY HH:mm") : null);
    // props.onChange(date);
  };

  const handleCalendarChange = (dates) => {
    console.log("OFDatepicker 2handleDateChange====> 2 ",dates,selectedDate)
    if (dates) {
      const newDate = dayjs(dates);
      if (!selectedDate || !newDate.isSame(selectedDate, "date")) {
        // If the date changes, reset time to 09:15
        setSelectedDate(newDate.hour(TRADING_HOURS.START_HOUR).minute(TRADING_HOURS.START_MINUTE));
      }
    }

  };
  // Handle DatePicker OK button
  const handleOk = (date) => {
    console.log("OFDatepicker 3 Date selected on OK:", date ? date.format("DD-MM-YYYY HH:mm") : null);
    if(selectedDate){
      setOKPressed(true);
      props.onChange(date);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const okButton = document.querySelector(".ant-picker-ok > button");
      if (okButton) {
        setOKPressed(true);
        okButton.click(); // Trigger the OK button programmatically
      }
    }
  };

 
  return (
    <DatePicker
    allowClear={false}
      onOpenChange={handleDatePickerOpenChange}
      format="DD-MM-YYYY HH:mm"
      showTime={{
        format: "HH:mm",
        use12Hours: false,
        defaultValue: dayjs().hour(9).minute(15), 
      }}
      disabledTime={memoizedDisabledDateTimeNew}
      disabledDate={disabledDate}
      placeholder="Select date and time"
      onChange={handleDateChange}
      onCalendarChange={handleCalendarChange}
      onOk={handleOk}
      value={selectedDate}
      style={{
        border: "1px solid #415e81",
        background: "#022D5A",
        borderRadius: "4px",
        width: 180,
        height:28,
        marginLeft: 36,
      }}
    />
  );
};

export default TradeDatesPicker;
