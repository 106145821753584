import InstrumentsDropdown from "./InstrumentsDropdown";
import { ConfigProvider } from "antd";
import { ToolbarFixed } from "../../../layouts/component.js";
import {useTheme,IconButton, Grid,Box, Typography} from "@mui/material";
import InstrumentsDropdownLC from "./InstrumentsDropdownNew.js";
import { useEffect } from "react";


const LCHeader=(props)=>{
    const theme=useTheme();
    useEffect(()=>{
// console.log("ISSUELC Straddle test LCHEader=",props.instrumentsData,props.selectedInstrument,props?.symbol)
    },[])
return(
    <ConfigProvider
    theme={{
      // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
      components: {
        
        Select: {
          colorPrimary: '#0d203f',
          colorBgContainer:"#0000ff",
          optionActiveBg:"#22314E",
          colorText:"#FFFFFF",
          optionSelectedBg:"#35445E",
          optionSelectedColor:"#FFFFFF",
          selectorBg:"#2f5cdb",
          colorTextPlaceholder:"#ffffff",
          colorIcon:"#ff0000",
          colorTextDisabled:"#899499"
          // algorithm: true, // Enable algorithm
        },
        
      },
    }}
  >
     {/* <ToolbarFixed sx={{borderTop: `2px solid ${theme.palette.primaryTheme.shade03}`,borderRight: `2px solid ${theme.palette.primaryTheme.shade03}`,paddingLeft:0,paddingRight:4,left:props.chartStateData.left, width:props.chartStateData.width}}> */}
     <Grid  container sx={{height:"100%"}} >
              {props.params && (props.params.plot=="opt"||props.params.plot=="fut")?
                  <Grid item mobile={12} tablet={12} laptop={12} >
                    <InstrumentsDropdownLC
                  //   width="250"
                  //   MenuProps={MenuProps}
                    selectedInstrument={props.selectedInstrument}
                    instrumentChange={props.instrumentChange}
                    instrumentsData={props.instrumentsData}
                  />
                </Grid>
                :
                <Grid item mobile={12} tablet={12} laptop={12} >
                  <Box sx={{display:"flex",width:"100%",justifyContent:'end',alignItems:'center'}}>
                    <Typography sx={{ml:2}}>{props.chartName}</Typography>
                  </Box>
                </Grid>
            }
      </Grid>
            
     {/* </ToolbarFixed> */}
     </ConfigProvider>  
)

}
export default LCHeader;