import { Rnd } from "react-rnd";
import React,{useCallback,useEffect, useState} from "react";
import { Box,Tabs,Tab,Divider,Tooltip ,Typography,Container} from '@mui/material';
import {useTheme} from "@mui/material";
import { RIGHT_PANEL_CONTAINER_WIDTH,CHART_COLORS } from '../../../common/utility/mp_chart_constants';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TABLE_COLORS } from '../../../common/utility/table_constants';
import ReactSpeedometer from "react-d3-speedometer"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { getType } from "@amcharts/amcharts5/.internal/core/util/Type";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MinimizeIcon from '@mui/icons-material/ExpandLessOutlined';
import MaximizeIcon from '@mui/icons-material/ExpandMoreOutlined';

const style = {
    display: "flex",
    flexDirection:"column",
    // alignItems: "center",
    justifyContent: "start",
    border: "solid 0.5px #285fbd",
    background: "#0d203f",
    zIndex:9999,
    borderRadius:5
  };

  const EllipsisTypography = ({ text }) => {
    return (
      <Tooltip title={text} placement="right" arrow>
        <Typography noWrap sx ={{ml:1}}style={{ overflow: 'hidden', textOverflow: 'ellipsis',width:"180px", maxWidth: '180px',minWidth: '180px',fontSize:12,fontWeight:400 }}>
          {text}
        </Typography>
      </Tooltip>
    );
  };
  const LinePreview = ({ lineType, lineWeight, color }) => {
    // console.log("preview type and color",lineType,color)
    const lineStyle = {
      width: '45px', // Set a fixed width for preview
      height: '1.5px', // Adjust the height according to your preference
    //   margin: '10px 0', // Adjust the margin according to your preference
    // marginLeft:"16px",
    marginTop:"8px",
      backgroundColor: color, // Color of the lines
    };
  
    const dashedLineStyle = {
      width:'45px',
    //   marginLeft:"16px",
      marginTop:"8px",
      borderBottom: lineType=="style_dashed_line"?`1.5px dashed ${color}`:`3px dashed ${color}`,
    };
  
    const dottedLineStyle = {
      width:'45px',
    //   marginLeft:"16px",
      marginTop:"8px",
      borderBottom: lineType=="style_dotted_line"?`1.5px dotted ${color}`:`3px dotted ${color}`,
    };
    // console.log("MPKRL",lineType,color)
  
    return (
    
      <>
      
        {lineType === 'style_line' && <div style={lineStyle}></div>}
        {lineType === 'style_thick_line' && <div style={{ ...lineStyle, ...{ height: '3px' } }}></div>}
        {lineType === 'style_dotted_line' && <div style={{ ...dottedLineStyle }}></div>}
        {lineType === 'style_dotted_thick_line' && (
          <div style={{...dottedLineStyle, ...{ height: '3px' } }}></div>
        )}
        {lineType === 'style_dashed_line' && <div style={{ ...dashedLineStyle }}></div>}
        {lineType === 'style_dashed_thick_line' && (
          <div style={{ ...dashedLineStyle, ...{ height: '3px' } }}></div>
        )}
        </>
     
    );
  };


  const MPKRLModal = (props) => {
    const [width,setWidth]=useState(160);
    const [height,setHeight]=useState(26);
    const [visible,setVisible]=useState(false);
    const theme=useTheme();
    const [anchoredKRL,setAnchoredKRL]=useState();  
    // const []
    const [xCord,setXCord]=useState(10);
    const [yCord,setYCord]=useState(36);
    // const [xCord,setXCord]=useState(20);
    // const [yCord,setYCord]=useState(24);

    useEffect(()=>{
        
        let filteredData=props.krlData.filter(item=>((item.category=="CUSTOM_ANCHORED_VPOC_KRL"||item.category=="CUSTOM_ANCHORED_TPOC_KRL" || item.category=="CUSTOM_ANCHORED_VWAP_KRL"||item.category=="CUSTOM_ANCHORED_VWAP_VPOC_KRL"|| item.category=="CUSTOM_ANCHORED_VWAP_TPOC_KRL")&& (item.type=="custom" || item.type=="bookmark_custom")))
        console.log("KRL TESTING MPKRLModal props=",props.krlData,filteredData)
        setAnchoredKRL(filteredData);
        setHeight(visible?filteredData.length*30+40:26)
        setWidth(visible?420:160)
    },[props.krlData])
  
    const toggleVisibility = () => {
      console.log("OFLiveModal handleClose");
      let temp=!visible
      setVisible(!visible)
      setHeight(temp?anchoredKRL.length*30+40:26);  
      setWidth(temp?420:160)
    
    };

    useEffect(()=>{
        console.log("MPKRLModal height=",height)
    },[height])
   
  
    const getType=(data)=>{
        if(data.category=="CUSTOM_ANCHORED_VPOC_KRL"){
            return "Anchored VPOC"
        }else if(data.category=="CUSTOM_ANCHORED_TPOC_KRL"){
            return "Anchored TPOC"
        }else if(data.category=="CUSTOM_ANCHORED_VWAP_KRL"){
            return "Anchored VWAP"
        }else if(data.category=="CUSTOM_ANCHORED_VWAP_VPOC_KRL"){
            return "Anchored VWAP VPOC"
        }
        else if(data.category=="CUSTOM_ANCHORED_VWAP_TPOC_KRL"){
            return "Anchored VWAP TPOC"
        }
    }    
   
  const deleteKRL=(data)=>{
    props.deleteKRL(data)
  }

  const editKRL=(data)=>{
    props.editKRL(data);

  }


   
   
    
    return (
      <>
    <Rnd
        style={style}
        resizeHandleStyles={{
          topRight:{cursor: 'default'},
          bottomRight:{cursor: 'default'},
          topLeft:{cursor: 'default'},
          bottomLeft:{cursor: 'default'},
          right:{cursor: 'default'},
          left:{cursor: 'default'},
          // top: {cursor: 'default'},
          // bottom:{cursor: 'default'},

        }}
        size={{ width: width, height: height }}
        position={{ x: xCord, y: yCord }}
        onDragStop={(e, d) => {
        //   this.setState({ xCord: d.x, yCord: d.y });
        setXCord(d.x);
        setYCord(d.y);
        }}
        onResizeStop={(e, direction, ref, delta, position) => {
        //   this.setState({
        //     width: ref.style.width,
        //     height: ref.style.height,
        //     ...position
        //   });
        // setWidth(width);
        console.log("width=",ref.style.width)
        setWidth(ref.style.width);
        setHeight(ref.style.height);
        setXCord(position.x);
        // setYCord(position.y);

        }}
        // minWidth={props.metaData.speedo&& props.chartWidth>490? 480:260}
        minWidth={width}
        maxWidth={width}
        // maxWidth={props.metaData.speedo&& props.chartWidth>490? 480:260}
        minHeight={height}
        maxHeight={height}
        bounds={props.boundClassName}
        cancel=".cancel"
      >
        {/* <div className="cancel" style={{display:'flex',flexDirection:'row',width:"160px"}}onClick={() => toggleVisibility()}> */}
            <Box display={'flex'} flexDirection={'row'}>
            <Typography sx={{ml:1,mt:0.2,fontSize:14}}>Anchored KRLs</Typography>
            {visible?
            <div className="cancel" >
            <MinimizeIcon
            onClick={() => toggleVisibility()}
            sx={{
              ml: 2, mt: 0.2, background: "#285fbd", opacity: 0.9,
            
              height: 20, width: 22, cursor: 'pointer', zIndex: 99999
            }}
          /></div>:
          <div className="cancel" >
          <MaximizeIcon
          onClick={() => toggleVisibility()}
            sx={{
              ml: 2, mt: 0.2, background: "#285fbd", opacity: 0.9,
            
              height: 20, width: 22, cursor: 'pointer', zIndex: 99999
            }}
          />
          </div>
        }
        </Box>
        {/* </div> */}
          {anchoredKRL?
          <>
<Box sx={{display:"flex",flexDirection:'column',paddingRight:"2px",width:"100%",height:"100%",overflowY:'auto',overflowX:'hidden'}}> 
<Box sx={{display:"flex",flexDirection:'column',pt:1}}>
    
    {/* <Box display="flex" justifyContent="flex-start" sx={{mb:1,ml:0}}>
      <Typography sx={{minWidth:"130px",ml:1,fontSize:14,color:"#92a9a9"}}>Type</Typography>
      <Typography sx={{minWidth:"60px",ml:1,fontSize:14,color:"#92a9a9"}}>Level</Typography>
      <Typography sx={{minWidth:"100px",ml:1,fontSize:14,color:"#92a9a9"}}>Description</Typography>
      <Typography sx={{minWidth:"70px",ml:2,fontSize:14,color:"#92a9a9"}}>Styling</Typography>
      <Typography sx={{minWidth:"70px",ml:2,fontSize:14,color:"#92a9a9"}}>Actions</Typography> 
     </Box>  */}



           
{anchoredKRL.map((item,index) => (
<Box sx={{display:"flex",mt:0,ml:1,height:"30px"}}> 
<Box sx={{width:"50px",minWidth:"50px", mt:-0.5, ml:0}}>
       {item.category=="CUSTOM_ANCHORED_VWAP_VPOC_KRL"|| item.category=="CUSTOM_ANCHORED_VWAP_TPOC_KRL"? 
      <>
      <LinePreview lineType={item.styling_category.split('.')[0]} lineWeight={1} color={item.marking_color.split('.')[0]} />
      <LinePreview lineType={item.styling_category.split('.')[1]} lineWeight={1} color={item.marking_color.split('.')[1]} />
      </>:
      <LinePreview lineType={item.styling_category} lineWeight={1} color={item.marking_color} />
        }


      
      </Box>
    {/* <Typography sx={{minWidth:"130px",ml:0,fontSize:12,fontWeight:400}}>{getType(item)}</Typography> */}
      <Typography sx={{minWidth:"75px",width:"75px", ml:1,fontSize:12,fontWeight:400}}>{item.start_date}</Typography>
      {props.hasFullAccess?
      // <Box sx={{marginLeft:"4px"}}>
      <Box sx={{ ml:1,display: "flex", alignItems: 'flex-start', justifyContent: "space-between", width: "50px" }}>
   
      <EditIcon  onClick={()=>editKRL(item)} sx={{color: "#ffffff",cursor:'pointer',ml:0,mt:0,width:20,height:20}}></EditIcon>
      <DeleteIcon onClick={()=>deleteKRL(item)} sx={{color: "#e24747",cursor:'pointer',ml:1,mt:0,width:20,height:20}}></DeleteIcon>
      </Box>:
      <></>
      }
      
      <EllipsisTypography text={item.description}/>
      
      

      {/* <Typography sx={{minWidth:"100px",ml:1,fontSize:12,fontWeight:400}}>{item.description}</Typography> */}
                  
                  
                 
                
               

                  {/* </Tooltip> */}
            </Box> 
             ))}

            
            
            
            
  

            

            

           
            </Box>
                
        </Box>
        </>:<></>
  }
      </Rnd>
        
      </>
    );
  };
  
  export default MPKRLModal;
  
