import { Rnd } from "react-rnd";
import React,{useCallback,useEffect, useState} from "react";
import { Box,Tabs,Tab,Divider,Tooltip ,Typography,Container} from '@mui/material';
import {useTheme} from "@mui/material";
import { RIGHT_PANEL_CONTAINER_WIDTH,CHART_COLORS } from '../../../common/utility/mp_chart_constants';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import RestartAltOutlinedIcon from '@mui/icons-material/FilterCenterFocusOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';

const style = {
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #285fbd",
    background: "#0d203fdd",
    zIndex:99999,
    borderRadius:"20px"
  };


  const MPFABModal = (props) => {
    const [width,setWidth]=useState(260);
    const [height,setHeight]=useState(210);
    const theme=useTheme();  
    const [xCord,setXCord]=useState(props.chartStateData.width/2-20);
    const [yCord,setYCord]=useState(props.chartStateData.height-props.bottomMargin-110);
    const [isDragging, setDragging] = useState(false)
    const [isTouchDevice,setIsTouchDevice]=useState(false);
  
    useEffect(()=>{
      function hasTouch() {
        return (('ontouchstart' in window) ||       // html5 browsers
                (navigator.maxTouchPoints > 0) ||   // future IE
                (navigator.msMaxTouchPoints > 0));  // current IE10
    }
    // if (!hasTouch()) alert('Sorry, need touch!');
    setIsTouchDevice(hasTouch());
    },[])
  
    useEffect(()=>{
        // console.log("OFLiveModal props=",props)
        setXCord(props.chartStateData.width/2-20);
        setYCord(props.chartStateData.height-props.bottomMargin-110);
    },[props.chartStateData,props.bottomMargin])
  
    const handleClick = (e) => {
        e.preventDefault();
        // console.log("Drag start handle click=",isDragging);
        if (isDragging === true) {
          return
        }
    
        props.recenterChart();
      }

      const handleClickRefresh= (e) => {
        e.preventDefault();
        // console.log("Drag start handle click=",isDragging);
        if (isDragging === true) {
          return
        }
    
        props.chartRefresh();
      }
   
  
        
   
  
   
   
    
    return (
      <>
    <Rnd
        style={style}
        resizeHandleStyles={{
          topRight:{cursor: 'default'},
          bottomRight:{cursor: 'default'},
          topLeft:{cursor: 'default'},
          bottomLeft:{cursor: 'default'},
          right:{cursor: 'default'},
          left:{cursor: 'default'},
          top: {cursor: 'default'},
          bottom:{cursor: 'default'},

        }}
        size={{ width: width, height: height }}
        position={{ x: xCord, y: yCord }}
        onDrag={(e, d) => {
            // console.log("Drag start =",isDragging);
            setDragging(true);

        }}
        onDragStop={(e, d) => {
        //   this.setState({ xCord: d.x, yCord: d.y });
        setXCord(d.x);
        setYCord(d.y);
        
        const timeout = setTimeout(() => {
            // console.log("Drag start End =",isDragging);
            setDragging(false);
        }, 100);
        
        }}
        onResizeStop={(e, direction, ref, delta, position) => {
        //   this.setState({
        //     width: ref.style.width,
        //     height: ref.style.height,
        //     ...position
        //   });
        // setWidth(width);
        setWidth(ref.style.width);
        setHeight(ref.style.height);
        setXCord(position.x);
        // setYCord(position.y);

        }}
        minWidth={props.liveComposite?90: 40}
        maxWidth={props.liveComposite?90: 40}
        minHeight={props.liveComposite?40: 40}
        maxHeight={props.liveComposite?40: 40}
        bounds={props.boundClassName}
        cancel=".cancel"
      >

<Box sx={{display:"flex",flexDirection:'row',width:"100%",height:"100%",overflowY:'hidden',overflowX:"hidden"}}> 
{props.liveComposite?
<Tooltip title="Refresh Live Composite">

<div className={isTouchDevice?"cancel":"dummy"} onClick={(e) => handleClickRefresh(e)}>
              <RefreshIcon 
                // onClick={(e)=>handleClick(e)}
                sx={{marginLeft:"10px",marginTop:"7px",height:24,width:24,cursor:'pointer', marginRight:"10px"}}>
              </RefreshIcon>
              </div>
            </Tooltip> 
            :<></>}

<Tooltip title="Reset Zoom / Recenter (keyboard shortcut: r)">
<div className={isTouchDevice?"cancel":"dummy"} onClick={(e) => handleClick(e)}>
              <RestartAltOutlinedIcon 
                // onClick={(e)=>handleClick(e)}
                sx={{marginLeft:"7px",marginTop:"7px",height:24,width:24,cursor:'pointer'}}>
              </RestartAltOutlinedIcon>
              </div>
            </Tooltip> 


</Box>
      </Rnd>
        
      </>
    );
  };
  
  export default MPFABModal;
  
