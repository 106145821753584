import React, { useState, useEffect, useMemo,useContext } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { UserSettingsContext } from "../../../setup/routes-manager/RequireAuth";



//MP CC date time 
const TradeDatesPicker = (props) => {
  const [tradeDates, setTradeDates] = useState(props.tradeDates || []);
  const [selectedDate, setSelectedDate] = useState(props.selectedDate||null);
  const { userSettings,setUserSettings, UIData } = useContext(UserSettingsContext);
 

  
  
  // Memoized function to disable specific dates
  const disabledDate = useMemo(() => {
    // console.log("BOBO Datetest disabledDate=",tradeDates)
    return (current) => {
      const formattedCurrentDate = current ? current.format("YYYY-MM-DD") : null;
      return !tradeDates.includes(formattedCurrentDate);
    };
  }, [tradeDates]);

  useEffect(()=>{
    console.log("DATETest CC 0",props.selectedDate,selectedDate)
    if(props.selectedTimeFrame){
      console.log("BOBO Datetest=",tradeDates,UIData)
    }
    
   },[])

   useEffect(()=>{
   
   setTradeDates(props.tradeDates)
    
   },[props.tradeDates])

 useEffect(()=>{
  console.log("DATETest CC 1",props.selectedDate,selectedDate)
  setSelectedDate(props.selectedDate);
 },[props.selectedDate])

  // Handle DatePicker change
  const handleDateChange = (date) => {
    setSelectedDate(date);
    console.log("DATETest CC 2 Selected date:", date ? date.format("DD-MM-YYYY HH:mm") : null);
    props.onChange(date);
  };

  // Handle DatePicker OK button
  const handleOk = (date) => {
    console.log("Date selected on OK:", date ? date.format("DD-MM-YYYY HH:mm") : null);
  };

  return (
    <DatePicker
      allowClear={false}
      format="DD-MM-YYYY"
      disabledDate={disabledDate}
      placeholder="Select date"
      onChange={handleDateChange}
      onOk={handleOk}
      value={selectedDate}
      style={{
        border: "1px solid #415e81",
        background: "#022D5A",
        borderRadius: "4px",
        width: 160,
        height:28,
        marginLeft: 36,
      }}
    />
  );
};

export default TradeDatesPicker;
